/* eslint-disable no-underscore-dangle */
import * as Sentry from '@sentry/browser';
import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_SET_COMPANY_LOGO,
  AUTH_SET_USER,
  SET_ADVISOR_BY_MANAGER,
  USER_LIST
} from 'constants/actions/auth';
import _ from 'lodash';
import DataProvider from 'providers/data';
import { toast } from 'react-toastify';
import {
  startAmplitude,
  startHubSpot,
  startLogRocket,
  startLogRocketImpersonate,
  stopAmplitude,
  stopLogRocketImpersonate
} from 'utils/tracking';
import config from '../config';

export const isInvestor = role => role === 'investor';
export const isAdmin = role => role === 'admin' || role === 4;
export const isManager = role => role === 'manager' || role === 3;
export const isCompliance = role => role === 'compliance' || role === 2;
export const isAdvisor = role => role === 'advisor' || role === 1;
export const isAnalyst = role => role === 'analyst' || role === 0;

export const ROLES = {
  values: {
    admin: 4,
    manager: 3,
    compliance: 2,
    advisor: 1,
    analyst: 0
  },
  labels: {
    admin: 'Executive',
    manager: 'Administrative',
    compliance: 'Compliance',
    advisor: 'Advisor',
    analyst: 'Analyst'
  },
  images: {
    admin: '/img/admin_icon.svg',
    manager: '/img/manager_icon.svg',
    compliance: '/img/manager_icon.svg',
    advisor: '/img/advisor_icon.svg',
    analyst: '/img/analyst_icon.svg'
  }
};

class AuthProvider extends DataProvider {
  NO_AUTH_REQUIRED_URL_PATTERNS = [
    /^\/investor/,
    /^\/forgot-password/,
    /^\/signin/,
    /^\/signup/,
    /^\/inapp-view/
  ];

  refreshTokenUrl = `${config.authBase}refresh-token/`;

  constructor(params) {
    super(params);
    this.sessionStorage = params.storage || window.sessionStorage;
    this._loggedUser = undefined;
    this._company = undefined;
  }

  // ============================  Registration  ============================ //

  get signupEmail() {
    return this.sessionStorage.getItem('signupEmail');
  }

  set signupEmail(email) {
    this.sessionStorage.setItem('signupEmail', email);
  }

  get signupPrice() {
    return this.storage.getItem('signupPrice');
  }

  set signupPrice(price) {
    this.storage.setItem('signupPrice', price);
  }

  signup(data) {
    const { email, price } = data;
    this.signupEmail = email;
    if (price) this.signupPrice = price;
    return this.provider.post(`${config.authBase}registration/`, data, null, {
      credentials: 'include'
    });
  }

  resendVerificationEmail() {
    return new Promise((resolve, reject) => {
      if (!this.signupEmail) reject({ error: 'No email found' });
      else
        this.provider
          .post(`${config.authBase}registration/resend-verification-email/`, {
            email: this.signupEmail
          })
          .then(resolve)
          .catch(reject);
    });
  }

  verifyEmail(key) {
    return this.provider
      .post(
        `${config.authBase}registration/verify-email/`,
        {
          key
        },
        null,
        { credentials: 'include' }
      )
      .then(r => {
        this.sessionStorage.removeItem('signupEmail');
        return r;
      });
  }

  // ===========================  Authentication  =========================== //

  get loggedUser() {
    if (!this._loggedUser) this._loggedUser = this.storage.getObject('loggedUser');
    return this._loggedUser;
  }

  set loggedUser(data) {
    this._loggedUser = data;
    if (data) this.storage.setObject('loggedUser', data);
    else this.storage.removeItem('loggedUser');
  }

  get company() {
    if (!this._company) this._company = this.storage.getObject('company');
    return this._company;
  }

  set company(data) {
    this._company = data;
    if (data) this.storage.setObject('company', data);
    else this.storage.removeItem('company');
  }

  /*
   * Authenticate in the platform using the user credentials
   */
  login(credentials, path = 'login') {
    return this.provider
      .post(`${config.authBase}${path}/`, credentials, null, { credentials: 'include' })
      .then(async ({ data, error }) => {
        if (!error) {
          data.token = data.access;
          delete data.access;
          this.loggedUser = data.user;
          await this.authenticate(data);
          toast.success(`Hi ${data.user.first_name} 👋 Welcome back!`);
        }
        return { data, error };
      });
  }

  loginWithGoogle(credentials) {
    return this.login(credentials, 'google');
  }

  loginWithMicrosoft(credentials) {
    return this.login(credentials, 'microsoft');
  }

  checkEmail(credentials) {
    return this.provider.post(`${config.authBase}check-email/`, credentials);
  }

  sendMagicLink(credentials) {
    return this.provider.post(`${config.authBase}send-magic-link/`, credentials);
  }

  /*
   * Refresh the authentication token for the logged user
   */
  refreshToken() {
    return this.provider
      .post(this.refreshTokenUrl, null, null, { credentials: 'include' })
      .then(async ({ data, error }) => {
        if (!error) {
          data.token = data.access;
          delete data.access;
          await this.authenticate(data);
        }
        if (error && this.storage.sessionToken) this.storage.removeItem('sessionToken');
        return { data, error };
      });
  }

  /*
   * Deauthenticate from the platform
   */
  logout() {
    // if the user is not authenticated, there is no need to logout
    if (!this.storage.getObject('user')) return Promise.resolve({ data: null, error: null });

    // Notice the empty object in the payload. This is an explicit API requirement.
    return this.provider
      .post(`${config.authBase}logout/`, {}, null, { credentials: 'include' })
      .then(({ data, error }) => {
        if (!error) this.deauthenticate();
        return { data, error };
      });
  }

  /*
   * Handle the effects of a successful authentication
   */
  async authenticate({ token, user, setAuthenticatedUser = false }) {
    if (token) {
      this.dispatch(AUTH_LOGIN, { data: token });
      this.provider.authenticate(token);
      if (setAuthenticatedUser) this.storage.setItem('sessionToken', token);
    }

    if (user) {
      // injects the company into the user's advisor (if applicable)
      if (!this.company && user.advisor) this.company = await this.getCompany();
      if (user.advisor) user.advisor.company = this.company;
      this.dispatch(AUTH_SET_USER, { data: user });
      this.storage.setObject('user', user);

      // starts HubSpot conversation widget
      const hsToken = await this.getHubSpotUserToken();
      if (hsToken) window.setHubSpotUser(user.email, hsToken);

      // start tracking user activity in third platforms
      startHubSpot(user);
      startLogRocket(user);
      startAmplitude(user, this.company);
      Sentry.configureScope(scope => {
        scope.setUser({ id: user.id });
      });
      if (setAuthenticatedUser) this.loggedUser = user;

      window.handleReferral(user.first_name, user.email, process.env.REFERRAL_FACTORY_CODE);
    }
  }

  /*
   * Handle the effects of a logout
   */
  deauthenticate(event) {
    const logoutTime = new Date();

    this.dispatch(AUTH_LOGOUT);
    this.provider.deauthenticate();
    this.storage.removeItem('user');
    this.storage.removeItem('sessionToken');
    this.company = null;
    this.loggedUser = null;

    // Save the logout event in the global localstorage
    // This should close sessions in other tabs.
    if (!event) this.storage.setItem('logout', logoutTime.getTime());

    // removes HubSpot conversation widget
    window.removeHubSpotWidget();

    // reset amplitude initialization
    stopAmplitude();

    // stop tracking user activity in third platforms
    Sentry.configureScope(scope => scope.setUser(null));

    // Clear the Referral Factory user information
    if (window.RF) window.location.reload();
  }

  getSession(token) {
    let headers = null;
    if (token) headers = { [config.apiTokenLabel]: `Token ${token}` };
    return this.provider.post(`${config.authBase}session/`, null, headers);
  }

  // ================================  User  ================================ //

  getUser(setAuthenticatedUser = false) {
    this.company = null;
    return this.provider
      .get(`${config.authBase}user/`)
      .then(async ({ data: user, error }) => {
        if (!error) await this.authenticate({ user, setAuthenticatedUser });
        return user;
      })
      .catch(data => {
        throw Error(data.message);
      });
  }

  changePassword(data) {
    return this.provider.post(`${config.authBase}password/change/`, data);
  }

  resetPasswordRequest(data) {
    return this.provider.post(`${config.authBase}password/reset/`, data);
  }

  resetPasswordConfirm(data) {
    return this.provider.post(`${config.authBase}password/reset/confirm/`, data);
  }

  setAdvisorByManager(advisorId) {
    return this.dispatch(SET_ADVISOR_BY_MANAGER, { data: advisorId });
  }

  // ==============================  HubSpot  =============================== //

  async getHubSpotUserToken() {
    const { data } = await this.provider.get(`${config.apiBase}hubspot/identify/`);
    return data?.token;
  }

  // ================================  2FA  ================================= //

  async configure2FADevice(payload = {}) {
    const { data, error } = await this.provider.post(
      `${config.authBase}mfa/device/configure/`,
      payload
    );
    return { data, error };
  }

  async register2FADevice(id, payload) {
    try {
      return await this.provider.post(`${config.authBase}mfa/device/${id}/confirm/`, payload);
    } catch (e) {
      throw new Error();
    }
  }

  async remove2FADevice(id, payload) {
    try {
      return await this.provider.post(`${config.authBase}mfa/device/${id}/delete/`, payload);
    } catch (e) {
      throw new Error();
    }
  }

  async newEmailPin2FADevice(payload) {
    try {
      return await this.provider.post(`${config.authBase}mfa/device/email/new-pin/`, payload);
    } catch (e) {
      throw new Error();
    }
  }

  // ==============================  Company  =============================== //

  async getCompany() {
    const { data } = await this.provider.get(`${config.apiBase}me/company/`);
    return data;
  }

  async updateCompany(values) {
    return this.provider.patch(`${config.apiBase}me/company/`, values);
  }

  async updateCompanyLogo(file) {
    const formData = this.formData({ file });
    const response = await this.provider.postFormData(
      `${config.apiBase}me/company/logo/`,
      formData,
      null,
      'PATCH'
    );
    if (response?.file) {
      // set the logo in the company object in the redux store
      this.dispatch(AUTH_SET_COMPANY_LOGO, { data: { file: response.file } });

      // set the logo in the user object in the local/session storage (if applicable)
      const user = this.storage.getObject('user');
      if (user?.advisor?.company && this.company) {
        this.company.logo = response.file;
        user.advisor.company.logo = response.file;
        this.storage.setObject('user', user);
      }
    }
    return response;
  }

  // ===========================  Impersonation  ============================ //

  listUsers() {
    return this.provider.get(`${config.authBase}users/`).then(response => {
      if (!response.error) this.dispatch(USER_LIST, { data: response.data });
    });
  }

  impersonateAction(action, data = null) {
    return this.provider
      .post(`${config.authBase}impersonate/${action}/`, data, null, { credentials: 'include' })
      .then(({ error }) => {
        if (!error) window.location.href = '/';
        else throw Error(error.message);
      })
      .finally(() => {
        this.company = null;
      });
  }

  startImpersonate(userId) {
    startLogRocketImpersonate();
    return this.impersonateAction('start', { user: userId });
  }

  stopImpersonate() {
    stopLogRocketImpersonate();
    return this.impersonateAction('stop');
  }

  // ============================  Permissions  ============================= //

  checkStoredUser() {
    if (this.loggedUser) return true;
    return false;
  }

  isAllowedWithoutAuth(pathname = window.location.pathname) {
    return Boolean(this.NO_AUTH_REQUIRED_URL_PATTERNS.find(pattern => pathname.match(pattern)));
  }

  isAuthenticated = user => user && !!user.id;

  hasPermission = (user, permission) => {
    if (!this.isAnyAdvisor(user)) return false;

    const advisorRole = ROLES.values[user.advisor.role];
    const permissionRole = ROLES.values[permission];

    if (typeof advisorRole === 'undefined' || typeof permissionRole === 'undefined') return false;

    return advisorRole >= permissionRole;
  };

  isInvestor = user => this.isAuthenticated(user) && !_.isEmpty(user.investor);

  isAnyAdvisor = user => this.isAuthenticated(user) && !_.isEmpty(user.advisor);

  isAdmin = user => this.isAnyAdvisor(user) && isAdmin(user.advisor.role);

  isManager = user => this.isAnyAdvisor(user) && isManager(user.advisor.role);

  isCompliance = user => this.isAnyAdvisor(user) && isCompliance(user.advisor.role);

  isAdvisor = user => this.isAnyAdvisor(user) && isAdvisor(user.advisor.role);

  isAnalyst = user => this.isAnyAdvisor(user) && isAnalyst(user.advisor.role);

  hasAdminPermissions = user => this.isAnyAdvisor(user) && this.hasPermission(user, 'admin');

  hasManagerPermissions = user => this.isAnyAdvisor(user) && this.hasPermission(user, 'manager');

  hasCompliancePermissions = user =>
    this.isAnyAdvisor(user) && this.hasPermission(user, 'compliance');

  hasAdvisorPermissions = user => this.isAnyAdvisor(user) && this.hasPermission(user, 'advisor');

  hasAnalystPermissions = user => this.isAnyAdvisor(user) && this.hasPermission(user, 'analyst');

  hasManagerPermissionsOrAbove = user =>
    this.isAnyAdvisor(user) &&
    (this.hasPermission(user, 'manager') || this.hasPermission(user, 'admin'));

  hasCompliancePermissionsOrAbove = user =>
    this.isAnyAdvisor(user) &&
    (this.hasPermission(user, 'compliance') ||
      this.hasPermission(user, 'manager') ||
      this.hasPermission(user, 'admin'));

  hasAddClientsPermissions = user =>
    this.hasCompliancePermissionsOrAbove(user) ||
    (this.isAdvisor(user) && user.advisor.company.allow_add_clients);

  hasAddProspectsPermissions = user =>
    this.hasCompliancePermissionsOrAbove(user) ||
    (this.isAnyAdvisor(user) && user.advisor.company.allow_add_prospects);

  hasUpdateTargetScorePermissions = user =>
    this.hasCompliancePermissionsOrAbove(user) ||
    user.advisor.company.allow_advisors_update_target_score_manually;

  hasCopyRiskTolerancePermissions = user =>
    this.hasCompliancePermissionsOrAbove(user) ||
    user.advisor.company.allow_advisors_copy_risk_tolerance_link;

  getLocalSessionToken() {
    return this.storage.getItem('sessionToken');
  }
}

export default AuthProvider;
