import cn from 'classnames';
import FooterSection from 'components/advisor/templates/sections/footer';
import Signature from 'components/advisor/templates/sections/signature';
import EditBox from 'components/advisor/utils/edit-box';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { byTemplateSectionOrder } from 'utils/utils';
import { sectionIsLocked } from 'components/advisor/section-locked/utils';
import { DISABLED_COMPONENT_PROPS } from 'components/advisor/utils/edit-box-text/utils';
import prepareMetadata from '../utils';
import IPSCoverSection from './sections/cover';
import CustomSection from './sections/custom';
import IPSDistributionSummary from './sections/distribution-summary';
import IPSEmbeddedDocument from './sections/embedded-document';
import IPSExecutiveSummary from './sections/executive-summary';
import { getTimeHorizon } from './sections/executive-summary/utils';
import IPSGeographicExposure from './sections/geographic-exposure';
import IPSInvestmentStyle from './sections/investment-style';
import IPSMatchingModels from './sections/matching-models';
import IPSPrism from './sections/prism';
import IPSRiskTolerance from './sections/risk-tolerance';
import IPSSectorExposure from './sections/sector-exposure';
import IPSTopHoldings from './sections/top-holdings';
import { IPSPropTypes } from './sections/types';

const IPS_COMPONENTS = {
  purpose: EditBox,
  background: EditBox,
  executiveSummary: IPSExecutiveSummary,
  statementOfObjectives: EditBox,
  matchingModels: IPSMatchingModels,
  riskTolerance: IPSRiskTolerance,
  riskToleranceChart: IPSPrism,
  assetClassPreference: EditBox,
  distributionSummary: IPSDistributionSummary,
  governance: EditBox,
  expectedReturns: EditBox,
  investmentStyle: IPSInvestmentStyle,
  sectorExposure: IPSSectorExposure,
  topHoldings: IPSTopHoldings,
  regionExposure: IPSGeographicExposure,
  _cover: IPSCoverSection,
  _footer: FooterSection,
  _investmentPolicyReview: EditBox,
  _signature: Signature
};

const ACCOUNT_ONLY_SECTION = 'accountOnly';

const IPSSection = ({ component, ...rest }) => {
  const { user } = useContext(AdvisorContext);

  const isSectionLocked = sectionIsLocked(rest.propertyKey, user);

  if (rest.hidden && isSectionLocked) return null;
  if (rest.hidden && !rest.allowHide) return null;
  if (rest.initialValues.attachment) return <IPSEmbeddedDocument {...rest} />;

  const Component = component ?? CustomSection;
  if (isSectionLocked) rest = { ...rest, ...DISABLED_COMPONENT_PROPS };

  return <Component {...rest} />;
};

IPSSection.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
};

IPSSection.defaultProps = {
  component: null
};

const IPSBody = ({
  actionsDisabled,
  disclosure,
  ipsCharts,
  ips,
  isReadOnly,
  saveChanges,
  toggleCustomAction,
  toggleVisibility
}) => {
  const { user } = useContext(AdvisorContext);

  const [editingSection, setEditingSection] = useState(null);

  const getSectionsMetadata = () => {
    const bySectionType = ([_, metaSection]) => !metaSection[ACCOUNT_ONLY_SECTION];
    const sections = prepareMetadata(ips, user);
    return Object.entries(sections).sort(byTemplateSectionOrder).filter(bySectionType);
  };

  const toggleEditing = key => () => {
    if (key === editingSection) setEditingSection(null);
    else setEditingSection(key);
  };

  const sections = getSectionsMetadata();
  const timeHorizon = useMemo(
    () => getTimeHorizon(ips.proposal),
    [JSON.stringify(ips.proposal.target)]
  );

  useEffect(() => {
    if (Number.isFinite(timeHorizon))
      saveChanges('executiveSummary', { investmentHorizon: timeHorizon });
  }, [timeHorizon]);

  return (
    <div className={cn('ips-body', { 'read-only': isReadOnly })}>
      {sections.map(([key, metaSection]) => (
        <IPSSection
          key={`box-${key}`}
          {...metaSection}
          actionsDisabled={actionsDisabled}
          className={cn(key, { opaque: editingSection && editingSection !== key })}
          component={IPS_COMPONENTS[key]}
          editing={editingSection === key}
          initialValues={metaSection}
          ips={ips}
          ipsCharts={ipsCharts}
          propertyKey={key}
          saveChanges={saveChanges}
          title={metaSection.title}
          toggleCustomAction={toggleCustomAction(key)}
          toggleEditing={toggleEditing(key)}
          toggleVisibility={toggleVisibility(key)}
        />
      ))}

      {disclosure && (
        <section className="disclosure">
          <h3>Disclosure</h3>
          <p dangerouslySetInnerHTML={{ __html: disclosure }} />
        </section>
      )}
    </div>
  );
};

IPSBody.defaultProps = {
  disclosure: null,
  isReadOnly: false,
  saveChanges: () => {},
  toggleCustomAction: () => {},
  toggleVisibility: () => {}
};

IPSBody.propTypes = {
  actionsDisabled: PropTypes.bool.isRequired,
  disclosure: PropTypes.string,
  ips: PropTypes.shape(IPSPropTypes).isRequired,
  ipsCharts: PropTypes.object.isRequired,
  isReadOnly: PropTypes.bool,
  saveChanges: PropTypes.func,
  toggleCustomAction: PropTypes.func,
  toggleVisibility: PropTypes.func
};

export default IPSBody;
