/* eslint-disable react/no-array-index-key */
import { Image, Text, View } from '@react-pdf/renderer';
import { MetadataPropTypes } from 'components/advisor/ips/body/sections/types';
import { AdvisorContext } from 'containers/advisor';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import BaseSection from 'reports/base/sections/base';
import { getTargetInvestors } from 'utils/utils';
import styles from './styles';

const SignaturePlace = ({ title, value, withCustomValue }) => (
  <>
    <View style={styles.signatureBox}>
      {withCustomValue ? value : <Text style={styles.signatureBoxText}>{value}</Text>}
    </View>
    <Text style={styles.signatureText}>{title}</Text>
  </>
);

SignaturePlace.defaultProps = {
  value: '',
  withCustomValue: false
};

SignaturePlace.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
  withCustomValue: PropTypes.bool
};

const Signature = ({ ips, metadata, reviews }) => {
  const {
    user: {
      advisor: {
        company: {
          digital_signature_enabled: digitalSignatureEnabled,
          requires_investor_signature: requiresInvestorSignature
        }
      }
    }
  } = useContext(AdvisorContext);

  const { body, signaturesNumber, title } = metadata;

  const investors = getTargetInvestors(ips.proposal);
  const requiredSignatures = metadata?.requiredSignatures || {};
  const requiredInvestors = investors.filter(investor => requiredSignatures[investor.id]);

  const getSignatureContent = (review, investor) => {
    if (requiresInvestorSignature && review?.reviewed_at && review?.signature)
      return <Image src={review.signature} />;
    if (!requiresInvestorSignature && review?.reviewed_at) {
      const nameArray = investor.name.split('');
      const nameInitials = nameArray.filter(char => /[A-Z]/.test(char));
      return <Text style={styles.signatureBoxText}>{`${nameInitials.join('. ')}.`}</Text>;
    }
    return null;
  };

  return (
    <BaseSection body={body} title={title} wrap={false}>
      <Text style={styles.headerText}>Prepared by</Text>
      <View style={styles.table}>
        <View style={styles.row}>
          <View style={styles.cell}>
            <SignaturePlace title="Advisor Name" />
          </View>
          <View style={styles.cell}>
            <SignaturePlace title="Signature" />
          </View>
          <View style={[styles.cell, { paddingRight: 0 }]}>
            <SignaturePlace title="Date" />
          </View>
        </View>
      </View>

      {(!digitalSignatureEnabled || !!requiredInvestors.length) && (
        <Text style={[styles.headerText, { marginTop: 20 }]}>Approved by</Text>
      )}

      {!digitalSignatureEnabled &&
        Array.from(Array(signaturesNumber ?? 1)).map((_, idx) => (
          <View key={`signature-${idx}`} style={styles.table} wrap={false}>
            <View style={styles.row}>
              <View style={styles.cell}>
                <SignaturePlace title="Client Name" />
              </View>
              <View style={styles.cell}>
                <SignaturePlace title="Signature" />
              </View>
              <View style={[styles.cell, { paddingRight: 0 }]}>
                <SignaturePlace title="Date" />
              </View>
            </View>
          </View>
        ))}

      {digitalSignatureEnabled &&
        requiredInvestors.map(investor => {
          const review = reviews.find(review => review.investor === investor.id) || {};
          const signatureContent = getSignatureContent(review, investor);
          const reviewedAt = review?.reviewed_at
            ? moment.utc(review.reviewed_at).format('lll')
            : null;
          return (
            <View key={`signature-${investor.id}`} style={styles.table} wrap={false}>
              <View style={styles.row}>
                <View style={styles.cell}>
                  <SignaturePlace title="Client Name" value={investor.name} />
                </View>
                <View style={[styles.cell, { flexGrow: 2 }]}>
                  <SignaturePlace title="Email" value={investor.email} />
                </View>
                <View style={styles.cell}>
                  <SignaturePlace title="Signature" value={signatureContent} withCustomValue />
                </View>
                <View style={[styles.cell, { paddingRight: 0, flexBasis: 45 }]}>
                  <SignaturePlace title="Date" value={reviewedAt} />
                </View>
              </View>
            </View>
          );
        })}
    </BaseSection>
  );
};

Signature.propTypes = {
  ips: PropTypes.shape({ proposal: PropTypes.object }).isRequired,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  reviews: PropTypes.array
};

Signature.defaultProps = {
  reviews: []
};

export default Signature;
