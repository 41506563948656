/* eslint-disable no-await-in-loop, no-restricted-syntax */
import SendEmailForm from 'components/advisor/investors/email-form';
import { DUMMY_INVESTOR } from 'components/advisor/investors/email-tolerance-form/utils';
import { Modal, ModalBody, ModalHeader } from 'components/modal';
import { AdvisorContext } from 'containers/advisor';
import { IPS_REVIEW_EMAIL } from 'containers/advisor/templates/company-meta/contants';
import { DEFAULT_IPS_REVIEW_EMAIL_TEMPLATE } from 'containers/advisor/templates/defaults';
import { IPS_REPORT_TYPE, PROPOSAL_REPORT_TYPE } from 'pages/proposal-or-ips-reports/constants';
import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import ReactDOM from 'react-dom';
import './styles.scss';
import { buildMessage, getReviewRequestUrl } from './utils';

const ProposalSignatureRequestModal = ({
  isIPS,
  reportId,
  reportInvestor,
  reviewerInvestors,
  title
}) => {
  if (!reviewerInvestors.length) return null;

  const {
    ipsProvider,
    proposalProvider,
    user: {
      advisor: { company }
    }
  } = useContext(AdvisorContext);

  const [isShown, setIsShown] = useState(false);

  const multipleInvestors = reviewerInvestors.length > 1;
  const reviewerInvestor = multipleInvestors ? DUMMY_INVESTOR : reviewerInvestors[0];
  const reportType = isIPS ? IPS_REPORT_TYPE : PROPOSAL_REPORT_TYPE;

  const hide = () => setIsShown(false);
  const show = () => setIsShown(true);

  const onSubmit = async ({ email, cc, sender, subject, msgGenerator }) => {
    const provider = isIPS ? ipsProvider : proposalProvider;

    for (const reviewerInvestor of reviewerInvestors)
      await provider
        .createReview(reportId, reportInvestor.id, reportInvestor.is_prospect, {
          investor: reviewerInvestor.id
        })
        .then(() => {
          provider.sendReviewMail(reportId, reportInvestor.id, reportInvestor.is_prospect, {
            investor_id: reviewerInvestor.id,
            email: multipleInvestors ? reviewerInvestor.email : email,
            cc,
            sender,
            subject,
            message: buildMessage(
              reviewerInvestor,
              reportId,
              reportType,
              reportInvestor,
              msgGenerator
            )
          });
        });

    await provider.getReport(reportId, reportInvestor.id, reportInvestor.is_prospect);

    hide();
  };

  const initialValues = {
    ...DEFAULT_IPS_REVIEW_EMAIL_TEMPLATE,
    ...company.ips_review_email,
    email: reviewerInvestor.email
  };

  const emailAction = !multipleInvestors
    ? {
        text: 'Review',
        url: getReviewRequestUrl(reviewerInvestor, reportId, reportType, reviewerInvestor)
      }
    : null;

  return (
    <>
      <button className="btn btn-success" onClick={show} type="button">
        Request Review
      </button>

      {ReactDOM.createPortal(
        <Modal
          id="signature-request-modal"
          className="modal-dialog--action-email"
          show={isShown}
          onHidden={hide}
        >
          <ModalHeader />
          <ModalBody>
            <SendEmailForm
              action={emailAction}
              className="target-score-questionnaire-email"
              emailDisabled={multipleInvestors}
              initialValues={initialValues}
              investor={reviewerInvestor}
              onCancel={hide}
              submit={onSubmit}
              template={{
                text: 'Edit Email Template',
                url: '/advisor/templates/ips-review-email/'
              }}
              title={title}
              type={IPS_REVIEW_EMAIL}
            />
          </ModalBody>
        </Modal>,
        document.getElementById('app-portal')
      )}
    </>
  );
};

ProposalSignatureRequestModal.propTypes = {
  isIPS: PropTypes.bool.isRequired,
  reportId: PropTypes.number.isRequired,
  reportInvestor: PropTypes.object.isRequired,
  reviewerInvestors: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired
};

export default ProposalSignatureRequestModal;
