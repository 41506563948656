/* eslint-disable prefer-destructuring */
import cn from 'classnames';
import DeleteTemplateSectionModal from 'components/advisor/templates/delete-template-section-modal';
import { sectionIsLocked } from 'components/advisor/section-locked/utils';
import AdministrativePermissionsTooltip from 'components/utils/administrative-permissions-tooltip';
import QuillField from 'components/utils/wysiwyg';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import { compose } from 'redux';
import { reduxForm } from 'redux-form';
import NewTag from 'app/containers/advisor/new-tag';
import { NEW_FEATURES } from 'app/containers/advisor/new-tag/new-features';
import {
  EDIT_RESTRICTED_SECTION,
  LOCKED_SECTION,
  VISIBILITY_RESTRICTED_SECTION,
  LOCKED_SECTION_HIDDEN
} from '../constants';
import './styles.scss';

export const SEPARATOR = '<hr />';

const INVESTMENT_POLICY_REVIEW = '_investmentPolicyReview';

export const PageBreak = ({ text }) => (
  <div className="page-break">
    <span>{text || 'Page Break'}</span>
  </div>
);

PageBreak.propTypes = {
  text: PropTypes.string
};

PageBreak.defaultProps = {
  text: ''
};

export class EditBox extends Component {
  constructor(props) {
    super(props);
    this.SEPARATOR = SEPARATOR;
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { editing, fields, body } = this.props;
    if (prevProps.editing !== editing) fields.body.onChange(body);

    if (fields.body.value && fields.body.value !== prevProps.fields.body.value)
      fields.prepend.value = fields.body.value.split(this.SEPARATOR).splice(1).join('');
  }

  readOnlyContent = x => {
    if (x) return x.split(this.SEPARATOR)[0];
    return '';
  };

  onSubmit(values) {
    const {
      allowChangeText,
      allowEdit,
      allowHide,
      footer,
      hidden,
      propertyKey,
      saveChanges,
      toggleEditing
    } = this.props;

    const showSeparator = footer ? '&nbsp;' : this.SEPARATOR;
    const isPrepend = values.prepend ? `${showSeparator} ${values.prepend}` : '';
    if (allowChangeText) values.body = values.body.split(showSeparator)[0];
    else values.body = values.body.split(showSeparator)[0] + isPrepend;

    return saveChanges(propertyKey, { ...values, allowEdit, allowHide, hidden }).then(() => {
      toast.success(() => 'Changes Saved successfully.');
      toggleEditing();
    });
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  setPermission(values) {
    const {
      allowChangeText,
      allowEdit,
      allowHide,
      body,
      editHeader,
      hidden,
      propertyKey,
      saveChanges,
      title,
      updateNewSectionPermissions
    } = this.props;

    /* Update local permission for new section */
    if (editHeader && updateNewSectionPermissions)
      updateNewSectionPermissions({
        allowChangeText,
        allowEdit,
        allowHide,
        hidden,
        title,
        ...values
      });
    else
      saveChanges(propertyKey, {
        allowChangeText,
        allowEdit,
        allowHide,
        body,
        hidden,
        title,
        ...values
      });
  }

  saveCustomSection = form => {
    const { saveChanges, allowEdit, allowHide, allowChangeText, toggleEditing } = this.props;
    form.body = form.body ? form.body.split(this.SEPARATOR)[0] : '';
    return saveChanges(form.title.trim(), { ...form, allowEdit, allowHide, allowChangeText }).then(
      () => {
        toggleEditing();
      }
    );
  };

  editHeader = () => {
    const { authProvider, user } = this.context;
    const { allowChangeText, fields, handleSubmit, submitting, toggleEditing } = this.props;

    const templateView = this.isTemplateView();
    const admin = authProvider.hasCompliancePermissions(user);

    return (
      <>
        <div className="advisor-edit-box-container__edit-header__buttons">
          <button
            className="advisor-edit-box-container__edit-header__buttons__cancel"
            disabled={submitting}
            onClick={() => toggleEditing()}
            type="button"
          >
            Cancel
          </button>
          <button
            className="btn btn-secondary advisor-edit-box-container__edit-header__buttons__save"
            disabled={submitting}
            onClick={handleSubmit(templateView ? this.saveCustomSection : this.onSubmit)}
            type="button"
          >
            {submitting ? 'Saving...' : 'Save'}
          </button>
        </div>

        <div
          className={cn('advisor-edit-box-container__edit-header__input', {
            'advisor-edit-box-container__edit-header__input--client-view': !templateView
          })}
        >
          <input
            {...fields.title}
            name="title"
            type="text"
            className={cn({
              'advisor-edit-box-container__edit-header__input--client-view': !templateView
            })}
            disabled={!templateView && !admin && !allowChangeText}
            placeholder="Custom section title..."
          />
        </div>
      </>
    );
  };

  getHeader = ({ useTooltip, actionsDisabled } = {}) => {
    const { authProvider, user } = this.context;
    const {
      customActions,
      deleteSection,
      disabledEditing,
      editing,
      handleSubmit,
      hidden,
      isCustomSection,
      propertyKey,
      submitting,
      toggleCustomAction,
      toggleEditing,
      toggleVisibility,
      allowHide,
      allowEdit, // Allow to add more content
      allowChangeText
    } = this.props;

    const templateView = this.isTemplateView();
    const admin = authProvider.hasCompliancePermissions(user);
    const isTemplateViewWithAdminPermissions = admin && templateView;
    const canToggleVisibility = allowHide || isTemplateViewWithAdminPermissions;
    const canToggleEdit = allowEdit || allowChangeText || isTemplateViewWithAdminPermissions;

    const isDisabledComponent = sectionIsLocked(propertyKey, user);

    return (
      <div className="box-header main">
        <div className="actions">
          {!isDisabledComponent && !canToggleVisibility && !canToggleEdit && (
            <span className="hidden-msg">{LOCKED_SECTION}</span>
          )}
          {!isDisabledComponent && hidden && !canToggleVisibility && (
            <span className="hidden-msg">{VISIBILITY_RESTRICTED_SECTION}</span>
          )}
          {isDisabledComponent && hidden && (
            <span className="hidden-msg">{LOCKED_SECTION_HIDDEN}</span>
          )}
          {!isDisabledComponent && !hidden && !canToggleEdit && (
            <span className="hidden-msg">{EDIT_RESTRICTED_SECTION}</span>
          )}

          <AdministrativePermissionsTooltip
            renderTooltip={useTooltip && actionsDisabled}
            tooltipId={`edit-or-hide-${propertyKey}`}
            wrapperClassName="section-actions"
          >
            {!isDisabledComponent &&
              !editing &&
              Object.entries(customActions).map(([actionId, action]) => (
                <label
                  key={`action-${propertyKey}-${actionId}`}
                  htmlFor={`action-toggle-${propertyKey}-${actionId}`}
                  className="action-toggle"
                >
                  <Toggle
                    id={`action-toggle-${propertyKey}-${actionId}`}
                    defaultChecked={action.value}
                    className="toggle-primary"
                    disabled={
                      (!action.allowed && !isTemplateViewWithAdminPermissions) ||
                      actionsDisabled ||
                      submitting ||
                      hidden
                    }
                    onChange={toggleCustomAction(actionId)}
                  />
                  <span>{action.label}</span>
                </label>
              ))}

            {!editing && canToggleVisibility && (
              <button
                className="btn btn-secondary btn-secondary-transparent"
                disabled={!canToggleVisibility || actionsDisabled || submitting}
                onClick={handleSubmit(toggleVisibility)}
                type="button"
              >
                {hidden ? 'Show' : 'Hide'}
              </button>
            )}

            {!isDisabledComponent && !editing && canToggleEdit && !disabledEditing && (
              <button
                className="btn btn-secondary"
                disabled={!canToggleEdit || actionsDisabled || submitting}
                onClick={toggleEditing}
                type="button"
              >
                Edit
              </button>
            )}
          </AdministrativePermissionsTooltip>

          {!editing && isCustomSection && isTemplateViewWithAdminPermissions && (
            <DeleteTemplateSectionModal
              label="custom section"
              onDelete={deleteSection}
              sectionKey={propertyKey}
              verb="Delete"
            />
          )}

          {editing && (
            <button
              className="btn btn-link text-dark cta"
              onClick={toggleEditing}
              type="button"
              disabled={submitting}
            >
              Cancel
            </button>
          )}

          {editing && (
            <button
              className="btn btn-secondary"
              disabled={submitting}
              onClick={handleSubmit(this.onSubmit)}
              type="button"
            >
              {submitting ? 'Saving...' : 'Save'}
            </button>
          )}
        </div>
      </div>
    );
  };

  isTemplateView = () => {
    const { location } = this.context;
    return location.pathname.match('advisor/templates/');
  };

  getBody = () => {
    const { body, preBody, title } = this.props;

    const newTagHtml = '<div class="new-tag">NEW</div>';
    if (!body && !preBody && !title) return null;

    let content = body || '';
    content = preBody ? preBody + content : content;
    content = title
      ? `<h2>${title} ${
          NEW_FEATURES.includes(title.toLowerCase()) ? newTagHtml : ''
        }</h2>${content}`
      : content;
    return content;
  };

  render() {
    const {
      actionsDisabled,
      allowChangeText, // This should allow to replace the text specified in the template
      body,
      children,
      className,
      customActions,
      disabledEditing,
      editHeader,
      editing,
      fields,
      hidden,
      propertyKey,
      title
    } = this.props;
    const customBody = this.getBody();
    const templateView = this.isTemplateView();

    return (
      <>
        {((customActions?.breakSection?.value && !hidden) ||
          propertyKey === INVESTMENT_POLICY_REVIEW) && <PageBreak />}
        <div
          className={cn(
            'edit-box advisor-edit-box-container',
            { 'advisor-edit-box-container--add-template': templateView },
            className,
            { hidden }
          )}
        >
          {editHeader || editing ? this.editHeader() : this.getHeader({ actionsDisabled })}

          {!editing && customBody && !disabledEditing && (
            <div
              className={cn('body', { hidden })}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: customBody }}
            />
          )}

          {editing &&
            (templateView ? (
              <QuillField className="body-wysiwyg" disabled={!editing} field={fields.body} />
            ) : (
              <div className={cn('body', { hidden })}>
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: allowChangeText ? '' : this.readOnlyContent(body)
                  }}
                />
                <QuillField
                  className="body-wysiwyg no-edit"
                  disabled={!editing}
                  field={allowChangeText ? fields.body : fields.prepend}
                />
              </div>
            ))}

          {children && !editing && disabledEditing && (
            <div className={cn('body no-edit', { hidden })}>
              <h3>
                {title} {NEW_FEATURES.includes(title.toLowerCase()) && <NewTag />}
              </h3>
              {children}
            </div>
          )}
        </div>
      </>
    );
  }
}

EditBox.contextTypes = {
  authProvider: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired
};

EditBox.propTypes = {
  actionsDisabled: PropTypes.bool,
  allowChangeText: PropTypes.bool,
  allowEdit: PropTypes.bool,
  allowHide: PropTypes.bool,
  body: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  className: PropTypes.string,
  customActions: PropTypes.objectOf(
    PropTypes.shape({
      default: PropTypes.bool,
      label: PropTypes.string.isRequired,
      permission: PropTypes.string,
      value: PropTypes.bool
    })
  ),
  deleteSection: PropTypes.func,
  disabledEditing: PropTypes.bool,
  editHeader: PropTypes.bool,
  editing: PropTypes.bool,
  fields: PropTypes.object.isRequired,
  footer: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  hidden: PropTypes.bool,
  isCustomSection: PropTypes.bool,
  isHidden: PropTypes.bool,
  preBody: PropTypes.string,
  propertyKey: PropTypes.string.isRequired,
  saveChanges: PropTypes.func,
  submitting: PropTypes.bool.isRequired,
  title: PropTypes.string,
  toggleEditing: PropTypes.func,
  toggleCustomAction: PropTypes.func.isRequired,
  toggleVisibility: PropTypes.func.isRequired,
  updateNewSectionPermissions: PropTypes.func
};

EditBox.defaultProps = {
  actionsDisabled: false,
  allowChangeText: false,
  allowEdit: false,
  allowHide: false,
  body: null,
  children: null,
  className: '',
  deleteSection: () => {},
  disabledEditing: false,
  editHeader: false,
  editing: false,
  footer: false,
  hidden: false,
  isCustomSection: false,
  isHidden: false,
  preBody: null,
  saveChanges: () => {},
  title: null,
  toggleEditing: () => {},
  updateNewSectionPermissions: () => {},
  customActions: {}
};

export default compose(
  connect((state, props) => ({ form: `${props.propertyKey}EditBox` })),
  reduxForm({ fields: ['body', 'prepend', 'title'], initialValues: {} })
)(EditBox);
