/* eslint-disable max-classes-per-file, jsx-a11y/label-has-associated-control, jsx-a11y/label-has-for */
import cn from 'classnames';
import Checkbox from 'components/form/checkbox';
import Radio from 'components/form/radio';
import PropTypes from 'prop-types';
import React, { Component, useEffect, useRef } from 'react';
import Autosuggest from 'react-autosuggest';

// Exprimental
// components for form controls
// https://github.com/erikras/redux-form

// Bootstrap and MaterialDesign support for input control
export const Input = ({
  autoComplete,
  autoFocus,
  checked,
  className,
  disabled,
  error,
  max,
  maxLength,
  min,
  name,
  onBlur,
  onBlurSkipError,
  onChange,
  onDragStart,
  onDrop,
  onPaste,
  onFocus,
  placeholder,
  refCb,
  skipError,
  style,
  type,
  value
}) => {
  const inputRef = refCb || useRef(null);

  useEffect(() => {
    if (autoFocus && inputRef.current) inputRef.current.focus();
  }, []);

  return (
    <input
      id={name}
      autoComplete={autoComplete}
      checked={checked}
      className={cn(className, {
        'has-value': !!value,
        'form-control-danger': error && type !== 'align'
      })}
      disabled={disabled}
      min={min}
      max={max}
      maxLength={maxLength || 255}
      name={name}
      onBlur={() => {
        if (onBlurSkipError) onBlurSkipError();
        onBlur();
      }}
      onChange={onChange}
      onPaste={onPaste}
      onDragStart={onDragStart}
      onDrop={onDrop}
      onFocus={() => {
        if (skipError) skipError();
        if (onFocus) onFocus();
      }}
      placeholder={placeholder}
      ref={inputRef}
      style={style}
      type={type || 'text'}
      value={value}
    />
  );
};

Input.defaultProps = {
  active: undefined,
  autoComplete: '',
  autofilled: undefined,
  autoFocus: false,
  checked: false,
  className: '',
  disabled: false,
  dirty: undefined,
  error: undefined,
  invalid: undefined,
  max: undefined,
  maxLength: undefined,
  min: undefined,
  onBlur: () => {},
  onBlurSkipError: () => {},
  onChange: () => {},
  onDragStart: () => {},
  onDrop: () => {},
  onFocus: () => {},
  onPaste: () => {},
  onUpdate: () => {},
  placeholder: undefined,
  pristine: undefined,
  refCb: undefined,
  skipError: undefined,
  style: undefined,
  touched: undefined,
  valid: undefined,
  visited: undefined
};

Input.propTypes = {
  active: PropTypes.bool,
  autoComplete: PropTypes.string,
  autofilled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  checked: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  dirty: PropTypes.bool,
  error: PropTypes.string,
  invalid: PropTypes.bool,
  max: PropTypes.number,
  maxLength: PropTypes.number,
  min: PropTypes.number,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onBlurSkipError: PropTypes.func,
  onChange: PropTypes.func,
  onDragStart: PropTypes.func,
  onDrop: PropTypes.func,
  onFocus: PropTypes.func,
  onPaste: PropTypes.func,
  onUpdate: PropTypes.func,
  placeholder: PropTypes.string,
  pristine: PropTypes.bool,
  refCb: PropTypes.object,
  skipError: PropTypes.bool,
  style: PropTypes.object,
  touched: PropTypes.bool,
  type: PropTypes.string.isRequired,
  valid: PropTypes.bool,
  value: PropTypes.any.isRequired,
  visited: PropTypes.bool
};

export const VerboseErrorInput = props => {
  const {
    children,
    error,
    errorAfterLabel,
    fieldsetClassName,
    label,
    maxLength,
    name,
    touched,
    dirty,
    value
  } = props;

  const defaultValue = value || '';
  const defaultMaxLength = maxLength || 11;
  const length = defaultMaxLength - defaultValue.length;

  return (
    <fieldset className={cn(fieldsetClassName, { 'with-label': !!label })}>
      {label && <label htmlFor={name}>{label}</label>}
      {errorAfterLabel && touched && dirty && error && (
        <span className="text-danger error"> {error}</span>
      )}
      {children}
      {!children && <Input {...props} />}
      {!errorAfterLabel && touched && dirty && error && (
        <span className="text-danger error">{error}</span>
      )}
      {maxLength && !error && (
        <div className="text-graphite-light text-size-xs text-xl-right">
          {length >= 0 ? `${length} characters left` : '0 characters left'}
        </div>
      )}
    </fieldset>
  );
};

VerboseErrorInput.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  error: PropTypes.string,
  errorAfterLabel: PropTypes.bool,
  fieldsetClassName: PropTypes.string,
  label: PropTypes.string,
  maxLength: PropTypes.number,
  name: PropTypes.string.isRequired,
  touched: PropTypes.bool,
  value: PropTypes.any
};

VerboseErrorInput.defaultProps = {
  children: null,
  error: null,
  errorAfterLabel: false,
  fieldsetClassName: '',
  label: null,
  maxLength: null,
  touched: false,
  value: ''
};

export class VerboseErrorSelect extends Component {
  static propTypes = {
    fieldData: PropTypes.object.isRequired,
    optionsData: PropTypes.array.isRequired,
    placeholderOption: PropTypes.string,
    lineBreakAfterLabel: PropTypes.bool,
    optionsAsObjects: PropTypes.bool,
    label: PropTypes.string,
    className: PropTypes.string,
    containerClassName: PropTypes.string,
    containerStyles: PropTypes.object,
    errorMessageStyles: PropTypes.object,
    style: PropTypes.object
  };

  static defaultProps = {
    optionsAsObjects: true,
    lineBreakAfterLabel: false,
    className: '',
    containerClassName: '',
    containerStyles: {},
    errorMessageStyles: {}
  };

  static toChoices(config) {
    return config.list.map(value => ({ label: config.labels[value], value }));
  }

  render() {
    const {
      optionsData,
      placeholderOption,
      fieldData,
      optionsAsObjects,
      label,
      className,
      containerClassName,
      containerStyles,
      style,
      lineBreakAfterLabel,
      errorMessageStyles
    } = this.props;
    const { touched, error } = fieldData;
    const invalid = !!(touched && error);
    const messageStyles = {
      marginTop: 10,
      fontSize: 12,
      ...errorMessageStyles
    };
    const wrapClass = className.indexOf('new-style') !== -1 ? 'new-style-arrow' : '';

    return (
      <fieldset className={containerClassName} style={containerStyles}>
        <FormGroup {...fieldData}>
          {label && <label>{label}</label>}
          {lineBreakAfterLabel && <br />}
          <div className={`c-select-wrap ${wrapClass}`} style={style}>
            <select className={`form-control c-select ${className}`} {...fieldData}>
              {placeholderOption && <option value="">{placeholderOption}</option>}
              {optionsData.map((item, index) => (
                <option value={optionsAsObjects ? item.value : item} key={index}>
                  {optionsAsObjects ? item.label : item}
                </option>
              ))}
            </select>
          </div>
          {invalid && (
            <div className="text-danger" style={messageStyles}>
              {error}
            </div>
          )}
        </FormGroup>
      </fieldset>
    );
  }
}

export const InputButton = ({ checked, field, multiple, option, styles, ...props }) => {
  const InputComponent = multiple ? Checkbox : Radio;
  const content = option.description
    ? `<strong>${option.label}</strong> (${option.description})`
    : option.label;
  const image = option.image ? { backgroundImage: `url(${option.image})` } : {};
  return (
    <label className={cn('radio-button', { checked })} style={{ ...image, ...styles }}>
      <InputComponent {...field} {...props} value={option.value} checked={checked} />
      <span dangerouslySetInnerHTML={{ __html: content }} />
    </label>
  );
};

export class QuestionInput extends Component {
  static propTypes = {
    fieldData: PropTypes.object.isRequired,
    placeHolderText: PropTypes.string.isRequired
  };

  render() {
    const { touched, error } = this.props.fieldData;
    this.props.fieldData.type = 'align';

    return (
      <fieldset
        className={cn('question-control question-input', {
          'question-control-invalid': touched && error
        })}
      >
        <Input placeholder={this.props.placeHolderText} {...this.props.fieldData} />
        {touched && error && <span className="text-danger">{error}</span>}
      </fieldset>
    );
  }
}

export class QuestionSelect extends Component {
  static propTypes = {
    fieldData: PropTypes.object.isRequired,
    defaultOption: PropTypes.string.isRequired,
    optionsData: PropTypes.array.isRequired
  };

  render() {
    const { optionsData, defaultOption, fieldData } = this.props;
    const { touched, error } = fieldData;
    const invalid = Boolean(touched && error);
    const styling = invalid ? { borderColor: 'red' } : {};

    return (
      <div className="question-control question-select">
        <div className="c-select-wrap">
          <select className="form-control c-select" style={styling} {...fieldData}>
            <option value="" disabled>
              {defaultOption}
            </option>
            {optionsData.map((item, i) => (
              <option value={item.value} key={i}>
                {item.label}
              </option>
            ))}
          </select>
        </div>
        {invalid && <div className="text-danger">{error}</div>}
      </div>
    );
  }
}

export class QuestionRadio extends Component {
  static propTypes = {
    fieldData: PropTypes.object.isRequired,
    optionsData: PropTypes.array.isRequired
  };

  render() {
    const { fieldData, optionsData } = this.props;
    const { name, value } = fieldData;

    return (
      <div className="question-radio">
        {optionsData.map((item, i) => (
          <div className="question-radio-item" key={i}>
            <label className="c-input c-radio">
              <input
                type="radio"
                name={name}
                checked={value === item.value}
                onClick={() => fieldData.onChange(item.value)}
              />
              <span className="c-indicator" />
            </label>
            <div>{item.label}</div>
          </div>
        ))}
      </div>
    );
  }
}

export const TableCellInput = props => {
  const { touched, error, tdConf } = props;

  const invalid = !!(touched && error);

  const invalidStyles = { borderBottom: '2px solid #FF002D' };

  const messageStyles = {
    marginTop: 10,
    marginBottom: -12,
    marginLeft: -14,
    fontSize: 12
  };

  return (
    <td style={invalid ? invalidStyles : null} {...tdConf}>
      <FormGroup {...props}>
        <Input type="text" className="form-control" {...props} />
        {invalid && (
          <div className="text-danger" style={messageStyles}>
            {error}
          </div>
        )}
      </FormGroup>
    </td>
  );
};

export const TableCellSelect = props => {
  const { touched, error } = props.fieldData;

  const { optionsData, defaultOption } = props;

  const invalid = !!(touched && error);

  const invalidStyles = { borderBottom: '2px solid #FF002D' };

  const messageStyles = {
    marginTop: 10,
    marginBottom: -12,
    marginLeft: -14,
    fontSize: 12
  };

  return (
    <td style={invalid ? invalidStyles : null}>
      <FormGroup {...props.fieldData}>
        <div className="c-select-wrap">
          <select className="form-control c-select" {...props.fieldData}>
            <option value="">{defaultOption}</option>
            {optionsData.map(item => (
              <option value={item} key={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
        {invalid && (
          <div className="text-danger" style={messageStyles}>
            {error}
          </div>
        )}
      </FormGroup>
    </td>
  );
};

export class VerboseErrorAutosuggest extends Component {
  constructor(...args) {
    super(...args);
    this.state = { touched: false };
    this.input = null;
    this.touch = this.touch.bind(this);
  }

  componentDidMount() {
    if (this.input && this.props.autofocus)
      setTimeout(() => {
        this.input.focus();
      }, 500);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.field.touched && !this.props.field.touched) this.touch();
  }

  storeInputReference = autosuggest => {
    if (autosuggest !== null) this.input = autosuggest.input;
  };

  touch() {
    if (!this.state.touched) this.setState({ touched: true });
  }

  validate(error) {
    if (error) return error;
    if (!this.props.suggestions.length) return 'No matching items exist';
  }

  render() {
    const { field, inputProps, type, ...rest } = this.props;
    let { error } = field;

    error = this.validate(error);

    const enhancedInputProps = {
      ...inputProps,
      onBlur: event => {
        if (type !== 'bulk') {
          this.touch();
        }
        if (inputProps.onBlur) {
          inputProps.onBlur(event);
        }
      }
    };

    return (
      <FormGroup {...field} touched={this.state.touched} className={rest.className}>
        <Autosuggest {...rest} ref={this.storeInputReference} inputProps={enhancedInputProps} />
        {this.state.touched && error && <span className="text-danger error">{error}</span>}
      </FormGroup>
    );
  }
}

export class AllowNewItemsAutosuggest extends VerboseErrorAutosuggest {
  validate(error) {
    if (error) return error;
  }
}

export function LabelInput(props) {
  const className = props.divClassName || '';
  const { label, invalid, error, showError, isOptional, type } = props;

  return (
    <div className={className}>
      <span className="span-label">
        {label}
        {isOptional ? <span className="optional-label"> - Optional</span> : ''}
      </span>
      <Input type={type || `text`} className="label-input" {...props} />
      {invalid && showError && <div className="text-danger">{error}</div>}
    </div>
  );
}

// Bootstrap and MaterialDesign support for textarea control
export function Textarea(props) {
  /*
    @props.value
    @props.error
    ...cols, rows
   */
  const className =
    (props.className || '') +
    (props.value ? ' has-value' : '') +
    (props.error ? ' form-control-danger' : '');

  return <textarea {...props} className={className} />;
}

// Helper for form-group
// example: <FormGroup {...field}></FormGroup>
export function FormGroup(props) {
  /*
    @props.value
    @props.error
    @props.validateNonTouched
   */
  const validateNonTouched = !!props.validateNonTouched;
  const className =
    (props.className || 'form-group') +
    ((props.touched || validateNonTouched) && props.error ? ' has-danger' : '');

  return (
    <div className={className} onClick={props.skipError || null} style={props.styles}>
      {props.children}
    </div>
  );
}

// Helper for form-group
// example: <TableFormGroup {...field}></TableFormGroup>
export function FormGroupTable(props) {
  /*
    @props.value
    @props.error
    @props.validateNonTouched
   */
  const validateNonTouched = !!props.validateNonTouched;
  const className =
    (props.className || 'form-group') +
    ((props.touched || validateNonTouched) && props.error ? ' has-danger' : '');

  return <td className={className}>{props.children}</td>;
}

// Helper for form-connected button groups
// @values: [['value1': 'Label1'], ['value2': 'Label2']]
export function ButtonGroup(props) {
  const values = props.values || [];
  const onChange = props.onChange || function () {};

  return (
    <div className="btn-group" style={props.style} role="group" aria-label="">
      {values.map(item => (
        <button
          key={item[0]}
          type="button"
          onClick={onChange.bind(null, item[0])}
          className={(props.className || '') + (props.value == item[0] ? ' active' : '')}
        >
          {item[1]}
        </button>
      ))}
    </div>
  );
}
