import {
  CHANGE_REPORT_ORIENTATION,
  CHANGE_REPORT_SIGNATURE_PAGE,
  PROPOSAL_CLEAR,
  PROPOSAL_CREATE,
  PROPOSAL_GET,
  PROPOSAL_MODEL_UPDATE,
  PROPOSAL_REPORTS_CREATE,
  PROPOSAL_REPORTS_GET,
  PROPOSAL_REPORTS_LIST,
  PROPOSAL_REPORTS_UPDATE,
  PROPOSAL_REPORT_CLEAR,
  PROPOSAL_REPORT_UNSAVED,
  PROPOSAL_SAVE_CHART_IMAGE,
  PROPOSAL_SAVE_REPORT_PDF_URL,
  PROPOSAL_SET_COLLAPSED_HOLDINGS,
  PROPOSAL_SET_COLLAPSED_REGIONS,
  PROPOSAL_SET_COLLAPSED_SECTORS,
  PROPOSAL_SET_COLLAPSED_STYLES,
  PROPOSAL_SET_COLLAPSED_TOP_HOLDINGS,
  PROPOSAL_UPDATE
} from 'constants/actions';
import { PORTRAIT_ORIENTATION } from 'reports/constants';
import { modelTitle } from 'utils/text';

const initialState = {
  charts: {},
  collapsedHoldings: [],
  collapsedStyles: [],
  collapsedSectors: [],
  collapsedTopHoldings: [],
  collapsedRegions: [],
  currentReport: null,
  currentReportSettings: { orientation: PORTRAIT_ORIENTATION, signaturePage: 0 },
  reports: {},
  url: null,
  view: null
};

// eslint-disable-next-line default-param-last
export default function proxies(state = initialState, action) {
  switch (action.type) {
    case PROPOSAL_GET:
    case PROPOSAL_CREATE:
      return {
        ...state,
        view: {
          ...action.data,
          target_label: action.data.target_label || modelTitle(action.data.target.display_name),
          benchmark_label: action.data.benchmark_label || 'Benchmark',
          recommended_label: action.data.recommended_label || 'Model',
          custom_target_label: action.data.target_label || '',
          custom_benchmark_label: action.data.benchmark_label || '',
          custom_recommended_label: action.data.recommended_label || ''
        }
      };

    case PROPOSAL_MODEL_UPDATE:
      return {
        ...state,
        view: { ...state.view, target_label: state.view.custom_target_label || action.data }
      };

    case PROPOSAL_UPDATE:
      return { ...state, view: { ...state.view, ...action.data } };

    case PROPOSAL_CLEAR:
      return {
        ...state,
        charts: {},
        collapsedHoldings: [],
        collapsedStyles: [],
        collapsedSectors: [],
        collapsedTopHoldings: [],
        collapsedRegions: [],
        url: null,
        view: null
      };

    case PROPOSAL_SAVE_CHART_IMAGE:
      return { ...state, charts: { ...state.charts, ...action.data } };

    case PROPOSAL_SET_COLLAPSED_HOLDINGS:
      return { ...state, collapsedHoldings: action.data };

    case PROPOSAL_SET_COLLAPSED_STYLES:
      return { ...state, collapsedStyles: action.data };

    case PROPOSAL_SET_COLLAPSED_SECTORS:
      return { ...state, collapsedSectors: action.data };

    case PROPOSAL_SET_COLLAPSED_TOP_HOLDINGS:
      return { ...state, collapsedTopHoldings: action.data };

    case PROPOSAL_SET_COLLAPSED_REGIONS:
      return { ...state, collapsedRegions: action.data };

    case PROPOSAL_REPORTS_LIST:
      return { ...state, reports: { ...state.reports, [action.id]: action.data } };

    case PROPOSAL_REPORTS_GET:
    case PROPOSAL_REPORTS_CREATE:
    case PROPOSAL_REPORTS_UPDATE:
      return { ...state, currentReport: action.data };

    case PROPOSAL_REPORT_UNSAVED:
      return { ...state, currentReport: { ...state.currentReport, unsaved: action.unsaved } };

    case PROPOSAL_SAVE_REPORT_PDF_URL:
      return { ...state, url: action.data };

    case PROPOSAL_REPORT_CLEAR:
      return { ...state, currentReport: null };

    case CHANGE_REPORT_ORIENTATION:
      return {
        ...state,
        currentReportSettings: { ...state.currentReportSettings, orientation: action.data }
      };

    case CHANGE_REPORT_SIGNATURE_PAGE:
      return {
        ...state,
        currentReportSettings: { ...state.currentReportSettings, signaturePage: action.data }
      };

    default:
      return state;
  }
}
