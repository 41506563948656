import DisplayScore from 'components/utils/DisplayScore';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import React from 'react';
import { INVESTOR_TARGET_TYPE } from 'components/advisor/risk-analysis/constants';
import {
  SECURITY_REGIONS,
  SECURITY_SECTORS,
  SECURITY_STYLES,
  SECURITY_UNDERLYING_MODEL,
  showSecurityDetailsSection
} from 'components/advisor/risk-analysis/securities/common/utils';
import NewTag from 'app/containers/advisor/new-tag';
import './styles.scss';

const ScrollCardsIndex = ({ isStrategy, score, type, portfolio }) => {
  const scrollToComponent = id => {
    const element = document.getElementById(id);
    if (element) {
      const headerOffset = 70;
      const elementPosition = element.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - headerOffset;

      const elementRect = element.getBoundingClientRect();
      const elementHeight = elementRect.height;
      const isLargeElement = elementHeight > 800;

      window.history.replaceState(null, null, `#${id}`);
      if (
        id === 'sector-exposure--header' ||
        id === 'geographic-exposure--header' ||
        isLargeElement
      )
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      else element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }
  };

  return (
    <div id="scroll-cards-index">
      <h2>Things to do</h2>
      <div className="scroll-cards__wrapper">
        <button
          type="button"
          className="card scroll-cards-container cards__hover"
          onClick={() => scrollToComponent('portfolio-risk')}
        >
          <DisplayScore
            isStrategy={isStrategy}
            score={parseFloat(score)}
            className="scroll-cards__score"
          />
          <div className="scroll-cards__text">
            <span className="scroll-cards__card-title ">Understand Portfolio Risk</span>
            <p>
              PRISM Analysis for Market Crisis Scenarios, Benchmark PRISM Rating ™, Risk Factor
              Analysis
            </p>
          </div>
        </button>
        <button
          type="button"
          className="card scroll-cards-container cards__hover"
          onClick={() => scrollToComponent('allocation')}
        >
          <img src="/img/icons/index/allocation_index.svg" alt="allocation" />
          <div className="scroll-cards__text">
            <span className="scroll-cards__card-title">See asset allocation</span>
            <p>
              The allocation that works best for you changes at different times in your life,
              depending on how long you have to invest and your ability to tolerate risk
            </p>
          </div>
        </button>
        {showSecurityDetailsSection(portfolio, SECURITY_STYLES) && (
          <button
            type="button"
            className="card scroll-cards-container cards__hover"
            onClick={() => scrollToComponent('investment-style')}
          >
            <div className="new-tag-index">
              <NewTag />
            </div>
            <img src="/img/icons/index/investment_style.svg" alt="investment_style" />
            <div className="scroll-cards__text">
              <span className="scroll-cards__card-title">Comprehend Investment Style</span>
              <p>See how you’re orienting investments, e.g. towards growth or value, etc.</p>
            </div>
          </button>
        )}
        {showSecurityDetailsSection(portfolio, SECURITY_SECTORS) && (
          <button
            type="button"
            className="card scroll-cards-container cards__hover"
            onClick={() => scrollToComponent('sector-exposure--header')}
          >
            <div className="new-tag-index">
              <NewTag />
            </div>
            <img src="/img/icons/index/sector_exposure.svg" alt="sector_exposure" />
            <div className="scroll-cards__text">
              <span className="scroll-cards__card-title">Sector Exposure</span>
              <p>
                PRISM Analysis for Market Crisis Scenarios, Benchmark PRISM Rating ™, Risk Factor
                Analysis
              </p>
            </div>
          </button>
        )}
        {showSecurityDetailsSection(portfolio, SECURITY_UNDERLYING_MODEL) && (
          <button
            type="button"
            className="card scroll-cards-container cards__hover"
            onClick={() => scrollToComponent('top-holdings')}
          >
            <div className="new-tag-index">
              <NewTag />
            </div>
            <img src="/img/icons/index/top_holdings.svg" alt="top_holdings" />
            <div className="scroll-cards__text">
              <span className="scroll-cards__card-title">Top 10 Holdings</span>
              <p>
                The allocation that works best for you changes at different times in your life,
                depending on how long you have to invest and your ability to tolerate risk
              </p>
            </div>
          </button>
        )}
        {showSecurityDetailsSection(portfolio, SECURITY_REGIONS) && (
          <button
            type="button"
            className="card scroll-cards-container index-security cards__hover"
            onClick={() => scrollToComponent('geographic-exposure--header')}
          >
            <div className="new-tag-index">
              <NewTag />
            </div>
            <img src="/img/icons/index/geographic_exposure.svg" alt="geographic_exposure" />
            <div className="scroll-cards__text">
              <span className="scroll-cards__card-title">Geographic Exposure</span>
              <p>See how you’re orienting investments, e.g. towards growth or value, etc.</p>
            </div>
          </button>
        )}
        <button
          type="button"
          className="card scroll-cards-container cards__hover"
          onClick={() => scrollToComponent('security-type-concentration')}
        >
          <img src="/img/icons/index/security_concentration.svg" alt="security_concentration" />
          <div className="scroll-cards__text">
            <span className="scroll-cards__card-title">Security Type Concentration</span>
            <p>
              PRISM Analysis for Market Crisis Scenarios, Benchmark PRISM Rating ™, Risk Factor
              Analysis
            </p>
          </div>
        </button>
        {type === INVESTOR_TARGET_TYPE && (
          <button
            type="button"
            className="card scroll-cards-container cards__hover"
            onClick={() => scrollToComponent('accounts-overview-table')}
          >
            <img src="/img/icons/index/account_balance.svg" alt="account_balance" />
            <div className="scroll-cards__text">
              <span className="scroll-cards__card-title">Accounts</span>
              <p>See how you’re orienting investments, e.g. towards growth or value, etc.</p>
            </div>
          </button>
        )}
      </div>
    </div>
  );
};

ScrollCardsIndex.propTypes = {
  isStrategy: PropTypes.bool,
  score: PropTypes.number,
  portfolio: PropTypes.object,
  type: PropTypes.string
};

ScrollCardsIndex.defaultProps = {
  isStrategy: false,
  score: null,
  portfolio: {},
  type: ''
};

const ScrollCardsIndexWithRouter = withRouter(ScrollCardsIndex);

export default ScrollCardsIndexWithRouter;
