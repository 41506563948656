/* eslint-disable no-underscore-dangle */
import { usePDF } from '@react-pdf/renderer';
import { trackAmplitudeEvent } from 'utils/tracking';
import { IPSPropTypes } from 'components/advisor/ips/body/sections/types';
import { PREPARED_BY_COMPANY } from 'components/advisor/templates/sections/cover/constants';
import LoadingButton from 'components/loading-button';
import { AdvisorContext } from 'containers/advisor';
import { IPS_DEFAULT_TEMPLATE } from 'containers/advisor/templates/defaults';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { generateReport, getInvestorReportCoverData, getReportMetadata } from 'reports/utils';
import ReportViewerModal from 'reports/viewer/modal';
import { getTargetAccountIds } from 'utils/utils';
import IPSReport from '..';
import { getFullyLoadedCharts } from './utils';

const QUESTIONNAIRE_SOURCE = 'Questionnaire';

const IPSReportViewer = ({
  collapsedHoldings,
  collapsedRegions,
  collapsedSectors,
  collapsedStyles,
  collapsedTopHoldings,
  currentReport,
  ips,
  isInvalidTemplate,
  orientation,
  questionnaires,
  reportUrl,
  savedCharts,
  subtitle,
  taxonomies
}) => {
  if (!ips) return null;

  const [report, setReport] = usePDF();

  const [attachments, setAttachments] = useState({});
  const [coverData, setCoverData] = useState({});
  const [downloadedExternalDocuments, setDownloadedExternalDocuments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [signaturePageNumber, setSignaturePageNumber] = useState(0);
  const [title, setTitle] = useState(undefined);
  const [totalPages, setTotalPages] = useState(0);
  const [url, setUrl] = useState(null);

  const { investorProvider, ipsProvider, prospectProvider, user, userProvider } =
    useContext(AdvisorContext);

  const fullyLoadedCharts = getFullyLoadedCharts(ips, savedCharts);
  const hasAttachments = !_.isEmpty(attachments);
  const fullyLoadedAttachments =
    !hasAttachments || Object.keys(attachments).length === downloadedExternalDocuments.length;

  const targetScoreSource = ips.proposal.target.group_details
    ? ips.proposal.target.group_details.map(account => account.target_score_source)
    : [ips.proposal.target.target_score_source];
  const fullyLoadedQuestionnaires =
    targetScoreSource.every(source => source !== QUESTIONNAIRE_SOURCE) ||
    (targetScoreSource.some(source => source === QUESTIONNAIRE_SOURCE) &&
      !!Object.keys(questionnaires).length);

  const partialLoadedReportAssets = fullyLoadedCharts && fullyLoadedQuestionnaires && !loading;
  const fullyLoadedReportAssets =
    fullyLoadedCharts && fullyLoadedQuestionnaires && fullyLoadedAttachments && !loading;

  const templateContent =
    ips.proposal.template_content || ips.proposal.template.content || IPS_DEFAULT_TEMPLATE;
  const coverSection = templateContent._cover;
  const proposalAccounts = getTargetAccountIds(ips.proposal);
  const coverParams = {
    accounts: proposalAccounts,
    prepared_by: coverSection.preparedBy,
    prepared_for: coverSection.preparedFor
  };
  const withCustomBranding = userProvider.isBusinessOrAbove(user);

  const changeOrientation = value => {
    ipsProvider.changeCurrentReportOrientation(value);
  };

  const handleSendToAddepar = () => {
    setSending(true);
    const { investor } = ips.proposal.target;
    ipsProvider
      .sendToAddepar(currentReport.id, investor, investor.is_prospect)
      .then(({ error }) => {
        if (error) throw Error();
        else toast.success('The PDF report was sent successfully');
      })
      .catch(() => {
        toast.error('Something went wrong while sending the PDF report');
      })
      .finally(() => {
        setSending(false);
      });
  };

  useEffect(() => {
    if (Number.isFinite(signaturePageNumber))
      ipsProvider.changeCurrentReportSignaturePage(signaturePageNumber);
  }, [signaturePageNumber]);

  useEffect(() => {
    setLoading(true);
    getInvestorReportCoverData(
      ips.proposal.target.investor,
      investorProvider,
      prospectProvider,
      coverParams
    )
      .then(data => {
        setCoverData({
          ...data,
          prepared_by_company: coverSection.preparedBy === PREPARED_BY_COMPANY
        });
        setTitle(coverSection.title);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [JSON.stringify(coverSection), JSON.stringify(proposalAccounts)]);

  // It's necessary to use the `usePDF` hook because the base report needs to be
  // rendered in order to obtain the number of total pages, as well as the pages where
  // external documents need to be inserted.
  useEffect(() => {
    setAttachments({});
    if (partialLoadedReportAssets) {
      setReport(undefined);
      setReport(
        <IPSReport
          collapsedHoldings={collapsedHoldings}
          collapsedRegions={collapsedRegions}
          collapsedSectors={collapsedSectors}
          collapsedStyles={collapsedStyles}
          collapsedTopHoldings={collapsedTopHoldings}
          coverData={coverData}
          ips={{ ...ips, proposal: { ...ips.proposal, ...savedCharts } }}
          orientation={orientation}
          questionnaires={questionnaires}
          setAttachments={setAttachments}
          setSignaturePageNumber={setSignaturePageNumber}
          setTotalPages={setTotalPages}
          subtitle={coverData.prepared_for || subtitle}
          taxonomies={taxonomies}
          title={title}
          totalPages={totalPages}
          user={user}
          withCustomBranding={withCustomBranding}
        />
      );
    }
  }, [
    JSON.stringify(collapsedHoldings),
    JSON.stringify(collapsedRegions),
    JSON.stringify(collapsedSectors),
    JSON.stringify(collapsedStyles),
    JSON.stringify(collapsedTopHoldings),
    JSON.stringify(coverData),
    JSON.stringify(ips),
    JSON.stringify(questionnaires),
    JSON.stringify(savedCharts),
    loading,
    orientation,
    title
  ]);

  useEffect(() => {
    if (fullyLoadedCharts && !!totalPages && report.url)
      generateReport(
        report.url,
        totalPages,
        attachments,
        downloadedExternalDocuments,
        setDownloadedExternalDocuments,
        getReportMetadata('Investment Policy Statement', subtitle)
      ).then(url => {
        if (!url) return;
        if (url !== reportUrl) ipsProvider.saveLatestPdfReportUrl(url);
        setUrl(url);
      });
  }, [fullyLoadedCharts, totalPages, report.url]);

  return (
    <>
      <ReportViewerModal
        changeOrientation={changeOrientation}
        fullyLoadedReportAssets={fullyLoadedReportAssets}
        isInvalidTemplate={isInvalidTemplate}
        orientation={orientation}
        url={url}
        onLoad={() => {
          const amplitudePost = {
            id: ips.id,
            investor: ips.proposal.target.investor.id,
            is_prospect: ips.proposal.target.investor.is_prospect,
            with_recommended: !!ips.proposal.recommended
          };
          trackAmplitudeEvent('ips.downloaded', amplitudePost);
        }}
      />

      {currentReport?.pdf_report && ips.proposal.can_send_to_addepar && (
        <LoadingButton
          type="button"
          onClick={handleSendToAddepar}
          className="btn btn-secondary"
          loading={sending}
        >
          Send to Addepar
        </LoadingButton>
      )}
    </>
  );
};

IPSReportViewer.propTypes = {
  collapsedHoldings: PropTypes.array.isRequired,
  collapsedRegions: PropTypes.array.isRequired,
  collapsedSectors: PropTypes.array.isRequired,
  collapsedStyles: PropTypes.array.isRequired,
  collapsedTopHoldings: PropTypes.array.isRequired,
  currentReport: PropTypes.object,
  ips: PropTypes.shape(IPSPropTypes),
  isInvalidTemplate: PropTypes.bool,
  orientation: PropTypes.string.isRequired,
  questionnaires: PropTypes.object.isRequired,
  reportUrl: PropTypes.string,
  savedCharts: PropTypes.object.isRequired,
  subtitle: PropTypes.string.isRequired,
  taxonomies: PropTypes.array.isRequired
};

IPSReportViewer.defaultProps = {
  currentReport: null,
  ips: null,
  isInvalidTemplate: false,
  reportUrl: null
};

export default connect(state => ({
  collapsedHoldings: state.ips.collapsedHoldings,
  collapsedRegions: state.ips.collapsedRegions,
  collapsedSectors: state.ips.collapsedSectors,
  collapsedStyles: state.ips.collapsedStyles,
  collapsedTopHoldings: state.ips.collapsedTopHoldings,
  currentReport: state.ips.currentReport,
  ips: state.ips.view,
  orientation: state.ips.currentReportSettings.orientation,
  questionnaires: state.questions.questionnaires,
  reportUrl: state.ips.url,
  savedCharts: state.ips.charts,
  taxonomies: state.market.taxonomies
}))(React.memo(IPSReportViewer));
