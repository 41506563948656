import cs from 'classnames';
import Choice from 'components/form/choice';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { integrationsConnectedSelector } from 'selectors/integrations';
import {
  getIntegrationsSyncData,
  getIntegrationsSyncDataByProviders,
  getSyncDataOptions,
  hasCustomIntegrationHandler
} from '../utils';
import './styles.scss';

const SyncDataOptions = ({
  integrations,
  onConnectOrUpdate,
  prevSelectedSyncData,
  provider,
  updating
}) => {
  // Notice these differences between data structures:
  // - prevSelectedSyncData (prop) and providedSyncData (variable) share the same structure.
  //   e.g. [{ id: 'sync_accounts', name: 'Accounts', description: ''}, { ... }, ...]
  // - selectedSyncData (state) has a different structure.
  //   e.g. { sync_accounts: true, ... }
  const connectedIntegrationsSyncData = getIntegrationsSyncData(integrations);
  const connectedIntegrationsSyncDataByProviders = getIntegrationsSyncDataByProviders(integrations);
  const hasConnectedIntegrations = !_.isEmpty(integrations);
  const providedSyncData = getSyncDataOptions(provider);

  const [selectedAll, setSelectedAll] = useState(false);
  const [selectedSyncData, setSelectedSyncData] = useState(
    providedSyncData.reduce((acc, syncDataOption) => ({ ...acc, [syncDataOption.id]: false }), {})
  );
  // If some key in selectedSyncData is true, it means there is at least
  // one item that can be synced from the provided data.
  const hasSelectedSyncData = Object.keys(selectedSyncData).some(d => selectedSyncData[d]);

  const showConnectedIntegrationsWarning =
    hasConnectedIntegrations &&
    !updating &&
    providedSyncData.some(option => connectedIntegrationsSyncData.includes(option.id));

  const onConnectOrUpdateIntegration = () => {
    if (onConnectOrUpdate) onConnectOrUpdate(selectedSyncData);
  };

  const onToggleSyncDataOption = optionId => {
    setSelectedSyncData({ ...selectedSyncData, [optionId]: !selectedSyncData[optionId] });
  };

  const onToggleAllSyncDataOptions = () => {
    setSelectedAll(!selectedAll);
  };

  useEffect(() => {
    setSelectedSyncData(
      Object.keys(selectedSyncData).reduce(
        (acc, syncDataOption) => ({ ...acc, [syncDataOption]: selectedAll }),
        {}
      )
    );
  }, [selectedAll]);

  // Used only for assign previous selected sync data options when updating
  useEffect(() => {
    if (updating)
      setSelectedSyncData(
        prevSelectedSyncData.reduce(
          (acc, syncDataOption) => ({ ...acc, [syncDataOption.id]: true }),
          {}
        )
      );
  }, []);

  return (
    <div id="sync-data-options">
      {!_.isEmpty(providedSyncData) && (updating || hasCustomIntegrationHandler(provider)) && (
        <>
          <h1>
            {updating
              ? `Update integrations with ${provider.name}`
              : `Select data to import from ${provider.name}`}
          </h1>
          <div className="provided-sync-data">
            {!hasConnectedIntegrations && (
              <div>
                <Choice checked={selectedAll} toggle={onToggleAllSyncDataOptions} />
                <span className="provided-sync-data__label">Select all</span>
              </div>
            )}
            {providedSyncData.map(syncDataOption => (
              <div key={syncDataOption.id}>
                <Choice
                  checked={
                    selectedSyncData[syncDataOption.id] ||
                    (!updating && connectedIntegrationsSyncData.includes(syncDataOption.id))
                  }
                  toggle={() => onToggleSyncDataOption(syncDataOption.id)}
                  disabled={!updating && connectedIntegrationsSyncData.includes(syncDataOption.id)}
                />
                <span
                  className={cs('provided-sync-data__label', {
                    disabled:
                      !updating && !!connectedIntegrationsSyncDataByProviders[syncDataOption.id]
                  })}
                >
                  {!updating && connectedIntegrationsSyncDataByProviders[syncDataOption.id]
                    ? `${syncDataOption.name} (Currently importing from ${
                        connectedIntegrationsSyncDataByProviders[syncDataOption.id]
                      })`
                    : syncDataOption.name}
                </span>
              </div>
            ))}
          </div>
          {showConnectedIntegrationsWarning && (
            <div className="sync-data-alert">
              <p>
                * If you want to replace the existing data, please disconnect it first. Go to the
                Connected integrations page and update the data selection.
              </p>
            </div>
          )}
          <div className="buttons-wrapper">
            <button type="button" data-dismiss="modal" className="btn btn-outline-primary">
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={onConnectOrUpdateIntegration}
              disabled={!hasSelectedSyncData && !updating}
            >
              {updating ? 'Update' : 'Connect'}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

SyncDataOptions.propTypes = {
  integrations: PropTypes.array.isRequired,
  onConnectOrUpdate: PropTypes.func,
  prevSelectedSyncData: PropTypes.array,
  provider: PropTypes.object.isRequired,
  updating: PropTypes.bool
};

SyncDataOptions.defaultProps = {
  onConnectOrUpdate: null,
  prevSelectedSyncData: [],
  updating: false
};

export default connect(state => ({
  integrations: integrationsConnectedSelector(state)
}))(SyncDataOptions);
