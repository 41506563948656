/* eslint-disable react/no-array-index-key */
import cn from 'classnames';
import TrashCanIcon from 'components/svg-icons/trash-can-icon';
import PropTypes from 'prop-types';
import React from 'react';

const SimpleSignature = ({ name, onDelete }) => (
  <div className={cn('signature-section', { 'signature-section--simple': !onDelete })}>
    <div className="signature-section__box">
      <div />
      <span>{name}</span>
    </div>
    <div className="signature-section__box">
      <div />
      <span>Signature</span>
    </div>
    <div className="signature-section__box">
      <div />
      <span>Date</span>
    </div>
    {onDelete && (
      <div className="signature-section__remove">
        <TrashCanIcon className="trash-icon" onClick={onDelete} title="Remove Signature" />
      </div>
    )}
  </div>
);

SimpleSignature.defaultProps = {
  name: 'Advisor Name',
  onDelete: null
};

SimpleSignature.propTypes = {
  name: PropTypes.string,
  onDelete: PropTypes.func
};

export default SimpleSignature;
