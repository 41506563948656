/* eslint-disable jsx-a11y/label-has-for, jsx-a11y/label-has-associated-control */
import {
  ALLOWED_LOCALES,
  ENGLISH_LANGUAGE,
  LANGUAGE_URL_PARAM,
  SPANISH_LANGUAGE
} from 'lang/constants';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

const LanguageButton = ({ currentLocale, locale, handleLocaleChange }) => (
  <button
    type="button"
    className="btn btn-link"
    disabled={currentLocale === locale}
    onClick={handleLocaleChange}
  >
    {locale}
  </button>
);

LanguageButton.propTypes = {
  currentLocale: PropTypes.string.isRequired,
  handleLocaleChange: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired
};

const NavigationInvestor = ({ user }) => {
  const url = new URL(window.location.href);

  const [currentLocale, setCurrentLocale] = useState(url.searchParams.get(LANGUAGE_URL_PARAM));

  if (!user || !user?.investor) return null;

  useEffect(() => {
    if (!currentLocale || !ALLOWED_LOCALES.includes(currentLocale)) {
      // adds the `lang` param to the URL if it doesn't exist
      // or if it's not a valid locale
      url.searchParams.set(LANGUAGE_URL_PARAM, ENGLISH_LANGUAGE);
      window.history.replaceState(null, '', url);
      setCurrentLocale(ENGLISH_LANGUAGE);
    } else if (url.searchParams.get(LANGUAGE_URL_PARAM) !== currentLocale) {
      // updates the `lang` param in the URL if it doesn't match the current locale
      url.searchParams.set(LANGUAGE_URL_PARAM, currentLocale);
      window.location.href = url.href;
    }
  }, [currentLocale]);

  const handleLocaleChange = locale => () => {
    setCurrentLocale(locale);
  };

  return (
    <div className="account-info">
      {user && !user.investor.is_default && (
        <div>
          <label>
            <FormattedMessage id="rtq.header.client" />
          </label>
          <span>{user.investor.full_name}</span>
        </div>
      )}
      <div className="language-options">
        <label>
          <FormattedMessage id="rtq.header.language" />
        </label>
        <div>
          <LanguageButton
            currentLocale={currentLocale}
            locale={ENGLISH_LANGUAGE}
            handleLocaleChange={handleLocaleChange(ENGLISH_LANGUAGE)}
          />{' '}
          |{' '}
          <LanguageButton
            currentLocale={currentLocale}
            locale={SPANISH_LANGUAGE}
            handleLocaleChange={handleLocaleChange(SPANISH_LANGUAGE)}
          />
        </div>
      </div>
    </div>
  );
};

NavigationInvestor.defaultProps = {
  user: null
};

NavigationInvestor.propTypes = {
  user: PropTypes.object
};

export default connect(state => ({
  user: state.auth.user
}))(NavigationInvestor);
