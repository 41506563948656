import { AdvisorPropTypes } from 'app/types';
import cn from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

const Header = ({ advisor, baseUrl, navigation, sidebar, mode }) => {
  const { company } = advisor;
  const hasUserImage = !!advisor.image;
  const logo = advisor.logo || advisor.company.logo;

  const brand = logo ? (
    <div className="nav-image-wrapper">
      <img
        alt={advisor.company_name || company.name || 'Logo'}
        src={logo}
        className={cn({
          'default-logo': company.default,
          'custom-logo': !company.default
        })}
      />
    </div>
  ) : (
    <h5 className="company-name">{advisor.company_name || company.name}</h5>
  );

  return (
    <nav
      id="Header"
      className={cn('navbar navbar-static-top flex align-stretch justify-between shown', {
        'user-pic': hasUserImage,
        'no-user-pic': !hasUserImage,
        loggedin: mode !== 'auth'
      })}
    >
      <div className="flex justify-start align-center drawer-switcher">
        {baseUrl ? (
          <a href={baseUrl} className="navbar-brand flex align-center">
            {brand}
          </a>
        ) : (
          <span className="navbar-brand flex align-center">{brand}</span>
        )}
      </div>

      {sidebar}

      {navigation}
    </nav>
  );
};

Header.propTypes = {
  baseUrl: PropTypes.string,
  advisor: PropTypes.shape(AdvisorPropTypes).isRequired,
  hasUserImage: PropTypes.bool,
  mode: PropTypes.string,
  navigation: PropTypes.object,
  sidebar: PropTypes.object
};

Header.defaultProps = {
  baseUrl: null,
  hasUserImage: false,
  mode: '',
  navigation: null,
  sidebar: null
};

export default Header;
