import MeetingCalendar from 'components/advisor/meeting-calendar';
import WidgetCodeIntegration from 'components/advisor/widget-code-integration';
import SpinnerLoader from 'components/performance-spinner';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import AdvisorProfileForm from './form';
import AdvisorBranding from './branding';
import './styles.scss';

const ADVISOR_PROFILE_SUFFIX = 'Profile';
const MEETING_CALENDAR_SUFFIX = 'Calendar';

const AdvisorProfile = ({ advisorId, setProfileTitle }) => {
  const { advisorProvider, authProvider, userProvider, user: loggedUser } = useContext(
    AdvisorContext
  );

  const [advisorProfileTitle, setAdvisorProfileTitle] = useState(undefined);
  const [meetingCalendarTitle, setMeetingCalendarTitle] = useState(undefined);
  const [user, setUser] = useState(null);

  const setSectionTitle = (user, suffix, setter) => {
    if (user && advisorId) {
      const advisorName = `${user.first_name} ${user.last_name}`;
      const sectionTitle = advisorName.endsWith('s')
        ? `${advisorName}' ${suffix}`
        : `${advisorName}'s ${suffix}`;
      setter(sectionTitle);
    } else setter(undefined);
  };

  const getAdvisorUser = async () => {
    const {
      data: { user, ...advisor },
      error
    } = await advisorProvider.getAdvisorProfile(advisorId);
    if (error) return null;
    return { ...user, advisor: { ...advisor, company: loggedUser.advisor.company } };
  };

  const setAdvisorUser = async () => {
    const user = advisorId ? await getAdvisorUser() : await authProvider.getUser();
    setUser(user);
  };

  const updateAdvisorProfile = async values =>
    advisorId
      ? advisorProvider.updateAdvisorProfile(advisorId, values)
      : userProvider.update(values);

  const updateAdvisorProfileImage = async (file, fieldName) =>
    advisorId
      ? advisorProvider.updateAdvisorProfileImage(advisorId, file, fieldName)
      : userProvider.updateImage(file, fieldName);

  useEffect(() => {
    // if `advisorId` is not provided, it means that the user is editing his own profile
    const setAdvisorUser = async () => {
      if (advisorId) {
        const user = await getAdvisorUser();
        setUser(user);
      } else setUser(loggedUser);
    };
    setAdvisorUser();
  }, [advisorId]);

  useEffect(() => {
    setSectionTitle(user, ADVISOR_PROFILE_SUFFIX, setAdvisorProfileTitle);
    setSectionTitle(user, MEETING_CALENDAR_SUFFIX, setMeetingCalendarTitle);
  }, [JSON.stringify(user)]);

  useEffect(() => {
    setProfileTitle(advisorProfileTitle);
  }, [advisorProfileTitle]);

  if (!user) return <SpinnerLoader spinnerLoading />;

  return (
    <div id="advisor-profile">
      <AdvisorProfileForm
        setAdvisorUser={setAdvisorUser}
        title={advisorProfileTitle}
        updateAdvisorProfile={updateAdvisorProfile}
        updateAdvisorProfileImage={updateAdvisorProfileImage}
        user={user}
      />

      <AdvisorBranding
        setAdvisorUser={setAdvisorUser}
        updateAdvisorProfile={updateAdvisorProfile}
        updateAdvisorProfileImage={updateAdvisorProfileImage}
        user={user}
      />

      <MeetingCalendar
        setAdvisorUser={setAdvisorUser}
        title={meetingCalendarTitle}
        updateAdvisorProfile={updateAdvisorProfile}
        user={user}
      />

      <WidgetCodeIntegration user={user} />
    </div>
  );
};

AdvisorProfile.defaultProps = {
  advisorId: null
};

AdvisorProfile.propTypes = {
  advisorId: PropTypes.string,
  setProfileTitle: PropTypes.func.isRequired
};

export default AdvisorProfile;
