/* eslint-disable react/no-array-index-key */
import InlineTextEditor from 'components/inline-text-editor';
import TooltipV2 from 'components/tooltip-v2';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Toggle from 'react-toggle';
import { validation } from 'utils/form';

const DigitalSignature = ({ investor, updateRequiredSignatures, updateSignatureEmail }) => {
  const [updating, setUpdating] = useState(false);

  const tooltipId = `signature-required-tooltip-${investor.id}`;

  const onSignatureRequiredChange = () => {
    updateRequiredSignatures(investor.id, !investor.required);
  };

  const onEmailChange = async email => {
    setUpdating(true);
    return updateSignatureEmail(investor, email).finally(() => {
      setUpdating(false);
    });
  };

  return (
    <div className="signature-section signature-section--digital">
      <div className="signature-section__box">
        <div>{investor.name}</div>
        <span>Client Name</span>
      </div>
      <div className="signature-section__box">
        <div>
          {updating ? (
            'Updating...'
          ) : (
            <InlineTextEditor
              onChange={onEmailChange}
              text={investor.email || '-'}
              validation={validation.email}
            />
          )}
        </div>
        <span>Email</span>
      </div>
      <div className="signature-section__box">
        <div />
        <span>Signature</span>
      </div>
      <div className="signature-section__box">
        <div />
        <span>Date</span>
      </div>
      <TooltipV2
        id={tooltipId}
        label="A valid email address is required"
        place="top"
        renderTooltip={!investor.email}
      >
        <label
          className="action-toggle"
          data-for={tooltipId}
          data-tip=""
          htmlFor={`action-toggle-${investor.id}`}
          key={`action-${investor.id}`}
        >
          <Toggle
            checked={investor.required}
            className="toggle-primary"
            disabled={!investor.email}
            id={`action-toggle-${investor.id}`}
            onChange={onSignatureRequiredChange}
          />
          <span>Review Required</span>
        </label>
      </TooltipV2>
    </div>
  );
};

DigitalSignature.propTypes = {
  investor: PropTypes.object.isRequired,
  updateRequiredSignatures: PropTypes.func.isRequired,
  updateSignatureEmail: PropTypes.func.isRequired
};

export default DigitalSignature;
