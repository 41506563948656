/* eslint-disable react/no-array-index-key */
import PlusIcon from 'assets/img/icons/plus-circle.svg';
import { IPSPropTypes } from 'components/advisor/ips/body/sections/types';
import EditBox from 'components/advisor/utils/edit-box';
import { AdvisorContext } from 'containers/advisor';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { getTargetInvestors } from 'utils/utils';
import DigitalSignature from './digital-signature';
import SimpleSignature from './simple-signature';
import './styles.scss';

const MIN_SIGNATURES_NUMBER = 1;

const Signature = ({
  ips,
  propertyKey,
  requiredSignatures: initialRequiredSignatures,
  saveChanges,
  signaturesNumber: initialSignaturesNumber,
  ...rest
}) => {
  const {
    investorProvider,
    ipsProvider,
    prospectProvider,
    user: {
      advisor: {
        company: { digital_signature_enabled: digitalSignatureEnabled }
      }
    }
  } = useContext(AdvisorContext);

  const [requiredSignatures, setRequiredSignatures] = useState(initialRequiredSignatures || {});
  const [signaturesNumber, setSignaturesNumber] = useState(initialSignaturesNumber);

  const investors = getTargetInvestors(ips.proposal);

  const signaturesNumberHandler = signaturesNumber => {
    saveChanges(propertyKey, { signaturesNumber });
    return signaturesNumber;
  };

  const updateSignatureEmail = async (investor, email) => {
    const provider = investor.is_prospect ? prospectProvider : investorProvider;
    return provider.update({ id: investor.id, email }).then(async () => {
      await ipsProvider.get(ips.id);
    });
  };

  const updateRequiredSignatures = (id, required) => {
    setRequiredSignatures(prevDigitalSignatures => ({ ...prevDigitalSignatures, [id]: required }));
  };

  const addSignature = () => {
    setSignaturesNumber(prevSignaturesNumber => signaturesNumberHandler(prevSignaturesNumber + 1));
  };

  const removeSignature = () => {
    setSignaturesNumber(prevSignaturesNumber => signaturesNumberHandler(prevSignaturesNumber - 1));
  };

  useEffect(() => {
    if (digitalSignatureEnabled && _.isEmpty(requiredSignatures))
      setRequiredSignatures(
        investors.reduce((acc, investor) => ({ ...acc, [investor.id]: !!investor.email }), {})
      );
  }, [JSON.stringify(investors)]);

  useEffect(() => {
    if (
      !_.isEmpty(requiredSignatures) &&
      JSON.stringify(requiredSignatures) !== JSON.stringify(initialRequiredSignatures)
    )
      saveChanges(propertyKey, { requiredSignatures });
  }, [JSON.stringify(requiredSignatures)]);

  return (
    <EditBox {...rest}>
      <div className="signature">
        <h4>Prepared by</h4>
        <SimpleSignature />

        <h4 className="mt-5">Approved by</h4>
        {!digitalSignatureEnabled && (
          <>
            {Array.from(Array(signaturesNumber)).map((_, idx) => (
              <SimpleSignature
                key={`signature-section-${idx}`}
                name="Client Name"
                onDelete={signaturesNumber > MIN_SIGNATURES_NUMBER ? removeSignature : null}
              />
            ))}

            <div className="signature__add">
              <button type="button" className="btn btn-link" onClick={addSignature}>
                <img src={PlusIcon} width={20} height={20} alt="Add" /> Add Signature
              </button>
            </div>
          </>
        )}

        {digitalSignatureEnabled &&
          investors.map(investor => (
            <DigitalSignature
              key={`signature-section-${investor.id}`}
              investor={{ ...investor, required: !!requiredSignatures[investor.id] }}
              updateRequiredSignatures={updateRequiredSignatures}
              updateSignatureEmail={updateSignatureEmail}
            />
          ))}
      </div>
    </EditBox>
  );
};

Signature.defaultProps = {
  requiredSignatures: {},
  signaturesNumber: 1
};

Signature.propTypes = {
  ips: PropTypes.shape(IPSPropTypes).isRequired,
  propertyKey: PropTypes.string.isRequired,
  requiredSignatures: PropTypes.object,
  saveChanges: PropTypes.func.isRequired,
  signaturesNumber: PropTypes.number
};

export default Signature;
