import ServerErrorNotification from 'components/server-error-notification';
import SplashLoading from 'components/splash-loading';
import _ from 'lodash';
import PropTypes from 'prop-types';
import AdvisorProvider from 'providers/advisor';
import AuthProvider from 'providers/auth';
import InvitationProvider from 'providers/invitation';
import ServerErrorsProvider from 'providers/server-errors-provider';
import TrustedDeviceProvider from 'providers/trusted-device';
import UserProvider from 'providers/user';
import React, { Component, createContext } from 'react';
import { connect } from 'react-redux';
import { routerActions } from 'react-router-redux';
import { bindActionCreators } from 'redux';

const NEXT = 'next';

export const AuthenticationContext = createContext({});

class Authentication extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true, // aka "validating"
      context: {
        authProvider: props.authProvider,
        invitationProvider: props.invitationProvider,
        routerActions: props.routerActions,
        trustedDeviceProvider: props.trustedDeviceProvider,
        userProvider: props.userProvider,
        user: props.user
      }
    };

    this.redirectUser = this.redirectUser.bind(this);
    this.syncLogout = this.syncLogout.bind(this);
  }

  getChildContext() {
    const { context } = this.state;
    return context;
  }

  componentDidMount() {
    const { authProvider } = this.props;

    const params = new URLSearchParams(window.location.search);

    const sessionToken = params.get('session')
      ? atob(params.get('session'))
      : authProvider.getLocalSessionToken();

    // checks if there is a token and if the URL starts with `/inapp-view` to log out and
    // prevent the user from being redirected to the sign in form instead
    if (sessionToken && window.location.pathname.startsWith('/inapp-view')) authProvider.logout();
    else if (sessionToken)
      authProvider.authenticate({ token: sessionToken, setAuthenticatedUser: true });

    authProvider
      .getUser(!!sessionToken)
      .then(user => {
        this.redirectUser(user);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });

    window.addEventListener('storage', this.syncLogout);
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    const { user: prevUser } = prevProps;
    const { context } = this.state;

    if (!_.isEqual(user, prevUser))
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ context: { ...context, user } }, () => {
        this.redirectUser(user);
      });
  }

  componentWillUnmount() {
    window.removeEventListener('storage', this.syncLogout);
  }

  syncLogout(event) {
    if (event.key === 'logout') {
      const { authProvider } = this.props;
      authProvider.deauthenticate(event);
    }
  }

  redirectUser(user) {
    const { authProvider, routerActions } = this.props;

    let redirect;
    const query = new URLSearchParams(window.location.search);
    const queryNext = query.get('next') || window.localStorage.getItem(NEXT);
    const queryEmail = query.get('email');

    if (!authProvider.isAuthenticated(user)) {
      if (!authProvider.isAllowedWithoutAuth()) {
        redirect = '/signin';
        if (window.location.pathname !== redirect && window.location.pathname !== '/') {
          // processes the original route path and clears the email parameter
          let previousRoute = window.location.pathname;
          if (queryEmail) query.delete('email');
          if (query.size) previousRoute = `${previousRoute}?${query.toString()}`;

          // saves the original route in the localStorage so that it can be used in case
          // there is no parameter in the URL
          window.localStorage.setItem(NEXT, previousRoute);

          // constructs the new route with the redirection and concatenates the email
          // parameter in case the original route had it
          redirect = `/signin?next=${previousRoute}`;
          if (queryEmail) redirect = `${redirect}&email=${queryEmail}`;
        }
      }
    } else {
      if (
        authProvider.isAnyAdvisor(user) &&
        !window.location.pathname.startsWith('/advisor') &&
        !window.location.pathname.startsWith('/developers') &&
        !window.location.pathname.startsWith('/inapp-view') &&
        !window.location.pathname.startsWith('/checkout')
      )
        redirect = '/advisor';

      if (authProvider.isInvestor(user) && !window.location.pathname.startsWith('/investor'))
        redirect = '/signin';

      if (queryNext) {
        // redirects and clears the localStorage of any existing redirection
        redirect = queryNext;
        window.localStorage.removeItem(NEXT);
      }
    }
    if (redirect) {
      // if we are redirecting to the signin page, we need to clear the session token
      // and the user data from the localStorage
      if (redirect.startsWith('/signin')) authProvider.deauthenticate();

      routerActions.push(redirect);
    }
  }

  render() {
    const { serverErrors, errorsProvider, children } = this.props;
    const { isLoading, context } = this.state;

    return (
      <AuthenticationContext.Provider value={context}>
        <div>
          <div className="error-container">
            {serverErrors.map((e, i) => (
              <ServerErrorNotification
                message={e}
                clickHandler={errorsProvider.skipError.bind(errorsProvider, i)}
              />
            ))}
            {serverErrors.length > 1 ? (
              <button
                type="button"
                className="btn btn-block skip-all-errors"
                onClick={() => errorsProvider.clearErrors()}
              >
                Skip all
              </button>
            ) : null}
          </div>
          {!isLoading ? children : <SplashLoading duration={0.25} />}
        </div>
      </AuthenticationContext.Provider>
    );
  }
}

const AuthenticationContextTypes = {
  authProvider: PropTypes.object.isRequired,
  invitationProvider: PropTypes.object.isRequired,
  routerActions: PropTypes.object.isRequired,
  trustedDeviceProvider: PropTypes.object.isRequired,
  userProvider: PropTypes.object.isRequired,
  user: PropTypes.object
};

Authentication.propTypes = {
  ...AuthenticationContextTypes,
  advisorProvider: PropTypes.object.isRequired,
  errorsProvider: PropTypes.object.isRequired,
  serverErrors: PropTypes.array.isRequired
};

Authentication.childContextTypes = {
  ...AuthenticationContextTypes
};

export default connect(
  state => ({
    serverErrors: state.serverErrors,
    user: state.auth.user
  }),
  dispatch => ({
    advisorProvider: new AdvisorProvider({ dispatch }),
    authProvider: new AuthProvider({ dispatch }),
    errorsProvider: new ServerErrorsProvider({ dispatch }),
    invitationProvider: new InvitationProvider({ dispatch }),
    routerActions: bindActionCreators(routerActions, dispatch),
    trustedDeviceProvider: new TrustedDeviceProvider({ dispatch }),
    userProvider: new UserProvider({ dispatch })
  })
)(Authentication);
