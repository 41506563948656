import { View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import CustomSection from 'reports/base/sections/custom';

const SectionWrapper = ({ component, setAttachments, ...rest }) => {
  const { metadata } = rest;

  // returns a dummy element in charge of simulating in which page the attachment type section
  // should be positioned. In this way, it's possible to determine on which page number it would be
  // and then merge it with the main document on the required page.
  if (metadata.attachment)
    return (
      <View
        render={({ pageNumber, totalPages }) => {
          if (Number.isFinite(totalPages))
            setAttachments(prevAttachments => ({
              ...prevAttachments,
              [metadata.attachmentId]: { pageNumber, url: metadata.url }
            }));
        }}
      />
    );

  const Component = component ?? CustomSection;
  const sectionStyles = !component ? { marginBottom: 20 } : {};

  return (
    <Component
      breakSection={metadata.customActions?.breakSection?.value ?? false}
      sectionStyles={sectionStyles}
      {...rest}
    />
  );
};

SectionWrapper.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  metadata: PropTypes.object.isRequired,
  setAttachments: PropTypes.func.isRequired
};

SectionWrapper.defaultProps = {
  component: null
};

export default SectionWrapper;
