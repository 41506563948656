/* global STANDARD_DATE_FORMAT */
import cn from 'classnames';
import LoadingPrismDataMessage from 'components/advisor/dashboard/loading-prism-data-message';
import {
  investorAccountsWithHouseholdSelector,
  investorSelector
} from 'components/advisor/investors/selectors';
import ProposalBody from 'components/advisor/proposal/body';
import { MODE_URL_PARAM, READ_ONLY_MODE } from 'components/advisor/proposal/constants';
import ProposalHeader from 'components/advisor/proposal/header';
import Disclosure from 'components/disclosure';
import SpinnerLoader from 'components/performance-spinner';
import {
  ACCOUNT_APP_MODEL,
  GENERATE_PROPOSAL,
  INVESTOR_APP_MODEL,
  MODEL_PORTFOLIO_APP_MODEL,
  SECURITY_APP_MODEL
} from 'constants/actstream';
import _ from 'lodash';
import { isReportReadOnly } from 'pages/proposal-or-ips-reports/constants';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { toast } from 'react-toastify';
import ReportViewer from 'reports/viewer';
import { allModelsWithPrismSelector } from 'selectors/models';
import { proposalTemplateListSelector } from 'selectors/templates';
import { getReportUrl } from 'utils/utils';
import './styles.scss';

const ProposalToast = ({ message }) => (
  <div className="proposal-toast generating-div">
    <SpinnerLoader spinnerLoading />
    <div className="text-div">{message}</div>
  </div>
);

ProposalToast.propTypes = {
  message: PropTypes.string.isRequired
};

class Proposal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { loading: false, ready: false };
  }

  componentDidMount() {
    const {
      accountProvider,
      clientProvider,
      marketProvider,
      modelProvider,
      proposalProvider,
      templateProvider
    } = this.context;
    const {
      params,
      route: { type },
      scope
    } = this.props;

    const initialLoad = [templateProvider.list()];

    const isModelPortfolioView = window.location.pathname.includes('/advisor/models');
    if (isModelPortfolioView)
      initialLoad.push(marketProvider.es.getSecurityBenchmarks(), modelProvider.listAll());

    if (type === 'account' && _.isEmpty(scope))
      initialLoad.push(accountProvider.get(params.accountId));
    else if (type === 'prospect' || type === 'investor')
      initialLoad.push(
        clientProvider.get(params.id),
        clientProvider.getAccountsWithHousehold(params.id)
      );

    Promise.all(initialLoad).then(() => {
      this.setState({ ready: true });
    });

    if (params.reportId) {
      this.setState({ loading: true });
      proposalProvider
        .getReport(params.reportId, params.id, type === 'prospect')
        .then(async ({ data }) => {
          await proposalProvider.get(data.proposal);
        })
        .finally(() => {
          this.setState({ loading: false });
        });
    }
  }

  componentWillUnmount() {
    const { proposalProvider } = this.context;
    proposalProvider.clear();
    proposalProvider.clearCurrentReport();
  }

  setMetadata = metadata => {
    const { proposalProvider } = this.context;
    const { currentReport, proposal } = this.props;
    return proposalProvider.update(proposal.id, { template_content: metadata }).then(() => {
      if (currentReport) proposalProvider.setCurrentReportUnsaved(true);
    });
  };

  logNewProposal = () => {
    const { actionProvider } = this.context;
    const {
      route: { type },
      scope
    } = this.props;

    const logParams = { verb: GENERATE_PROPOSAL, target_id: scope.id };

    if (type === 'model') logParams.target_app_model = MODEL_PORTFOLIO_APP_MODEL;
    else if (type === 'account') logParams.target_app_model = ACCOUNT_APP_MODEL;
    else if (type === 'investor' || type === 'prospect')
      logParams.target_app_model = INVESTOR_APP_MODEL;
    else if (type === 'security') logParams.target_app_model = SECURITY_APP_MODEL;

    if (logParams.target_app_model) actionProvider.slack(logParams);
  };

  setLoading = () => {
    this.setState({ loading: true });
  };

  generateProposal = values => {
    const {
      route: { type }
    } = this.props;

    this.setState({ loading: true });

    const { proposalProvider } = this.context;
    const proposalNotificationMessage =
      type === 'model' ? 'Running Analysis' : 'Generating Proposal';
    const proposalNotification = toast.info(
      <ProposalToast message={proposalNotificationMessage} />,
      { closeButton: true, autoClose: false, closeOnClick: false, className: 'proposal-toast' }
    );

    return proposalProvider
      .create(values)
      .then(response => {
        const { data, error } = response;
        if (_.isEmpty(data) || error)
          toast.update(proposalNotification, {
            type: toast.TYPE.ERROR,
            render: 'Something went wrong while generating the proposal',
            closeOnClick: true
          });
        else this.logNewProposal();
        toast.update(proposalNotification, { autoClose: 100 });
        return response;
      })
      .catch(error => {
        toast.update(proposalNotification, {
          type: toast.TYPE.ERROR,
          render: 'Error generating proposal',
          closeOnClick: true
        });
        return error;
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { loading, ready } = this.state;
    const {
      accounts,
      currentReport,
      location,
      models,
      params,
      proposal,
      proposalCharts,
      route: { type },
      securities,
      scope,
      templates
    } = this.props;

    // Show loader if one of these conditions are valid:
    // - It's not ready yet.
    // - It's a model or account proposal type and the scope it's not loaded.
    if (
      !ready ||
      ((type === 'account' || type === 'model' || type === 'security') && _.isEmpty(scope))
    )
      return <SpinnerLoader spinnerLoading />;

    // Show an error message in case the previous conditions were fulfilled but
    // the accounts prop is empty and the type refers to a prospect or investor.
    // This could indicate that all existing accounts are excluded.
    if ((type === 'prospect' || type === 'investor') && !accounts.length)
      return (
        <div className="proposal-accounts-error">
          <p>
            All accounts have been excluded. Please check the overview page to ensure these accounts
            should have been excluded.
          </p>
          <Link
            className="btn btn-primary"
            to={{
              pathname: `/advisor/${type}s/${params.id}/overview`,
              hash: '#accounts'
            }}
          >
            View accounts list
          </Link>
        </div>
      );

    const query = new URLSearchParams(window.location.search);
    const accountOptions = accounts.filter(a => !!a.prism_score_summary);
    const isReadOnly =
      query.get(MODE_URL_PARAM) === READ_ONLY_MODE || isReportReadOnly(currentReport?.status);
    const proposalIsReady = !loading && !_.isEmpty(proposal) && proposal.status === 'complete';
    const selectedModelId = type === 'model' || type === 'security' ? Number(params.id) : null;

    return (
      <div id="Proposal" className="proposal">
        <div className="proposal-container">
          <ProposalHeader
            accountOptions={accountOptions}
            generateProposal={this.generateProposal}
            loading={loading}
            modelOptions={models}
            proposal={proposal}
            proposalType={type}
            ready={proposalIsReady}
            scope={scope}
            securityOptions={securities}
            selectedModelId={selectedModelId}
            setLoading={this.setLoading}
            templateOptions={templates}
          />
          {loading && !isReadOnly && (
            <LoadingPrismDataMessage
              message={params.reportId ? 'Getting proposal...' : 'Generating proposal...'}
              inset
            />
          )}
          {proposalIsReady && !isReadOnly && (
            <ProposalBody
              className={cn('proposal-body', { 'has-no-recommended': !proposal.recommended })}
              proposal={proposal}
              proposalCharts={proposalCharts}
              proposalType={type}
              setMetadata={this.setMetadata}
            />
          )}
          {isReadOnly && currentReport && <ReportViewer url={getReportUrl(currentReport)} />}
          {location.pathname.includes('/performance-analysis') && <Disclosure />}
        </div>
      </div>
    );
  }
}

Proposal.contextTypes = {
  accountProvider: PropTypes.object.isRequired,
  actionProvider: PropTypes.object.isRequired,
  clientProvider: PropTypes.object.isRequired,
  marketProvider: PropTypes.object.isRequired,
  modelProvider: PropTypes.object.isRequired,
  proposalProvider: PropTypes.object.isRequired,
  templateProvider: PropTypes.object.isRequired
};

Proposal.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object),
  currentReport: PropTypes.object,
  investor: PropTypes.object,
  location: PropTypes.object.isRequired,
  model: PropTypes.object,
  models: PropTypes.arrayOf(PropTypes.object).isRequired,
  params: PropTypes.object.isRequired,
  proposal: PropTypes.object,
  proposalCharts: PropTypes.object.isRequired,
  route: PropTypes.shape({
    type: PropTypes.string.isRequired,
    clientType: PropTypes.string
  }).isRequired,
  scope: PropTypes.object,
  securities: PropTypes.arrayOf(PropTypes.object),
  security: PropTypes.object,
  templates: PropTypes.array.isRequired
};

Proposal.defaultProps = {
  accounts: [],
  currentReport: null,
  investor: {},
  model: {},
  proposal: {},
  scope: {},
  securities: [],
  security: {}
};

function mergeProps(stateProps, dispatchProps, ownProps) {
  const { params, route: { type } = {} } = ownProps;
  const { account, model, security } = stateProps;
  let { investor } = stateProps;

  /* Assign the proper scope:
    - if the type is 'model' and the current model matches the 'id' in the url, use it.
    - if the type is 'account' and the current account matches the 'account' in the url, use it.
    - if the type is prospect|investor and the current prospect|investor matches the 'prospect|investor' in the url, use it.
  Also, if the type is 'account', the investor should be the one inside the current account (if available)
  */
  let scope = null;

  if (type === 'model' && !_.isEmpty(model) && model.id === parseInt(params.id, 10)) scope = model;
  else if (
    type === 'account' &&
    !_.isEmpty(account) &&
    account.id === parseInt(params.accountId, 10)
  ) {
    scope = account;
    investor = account && !_.isEmpty(account) && account.investor ? account.investor : investor;
  } else if (
    (type === 'investor' || type === 'prospect') &&
    !_.isEmpty(investor) &&
    investor.id === parseInt(params.id, 10)
  )
    scope = investor;
  else if (type === 'security' && !_.isEmpty(security) && security.id === parseInt(params.id, 10))
    scope = security;

  return {
    ...stateProps,
    ...ownProps,
    scope,
    investor
  };
}

export default connect(
  state => ({
    account: state.accounts.view,
    accounts: investorAccountsWithHouseholdSelector(state),
    currentReport: state.proposals.currentReport,
    investor: investorSelector(state),
    model: state.models.view,
    models: allModelsWithPrismSelector(state),
    proposal: state.proposals.view,
    proposalCharts: state.proposals.charts,
    security: state.customSecurity.currentSecurity,
    securities: state.market.securities.benchmarks,
    templates: proposalTemplateListSelector(state)
  }),
  null,
  mergeProps
)(Proposal);
