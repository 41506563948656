import { usePDF } from '@react-pdf/renderer';
import LoadingPrismDataMessage from 'components/advisor/dashboard/loading-prism-data-message';
import {
  investorAccountsWithHouseholdSelector,
  investorSelector
} from 'components/advisor/investors/selectors';
import {
  INVESTOR_PROPOSAL_TYPE,
  MODE_URL_PARAM,
  PROSPECT_PROPOSAL_TYPE,
  READ_ONLY_MODE
} from 'components/advisor/proposal/constants';
import SpinnerLoader from 'components/performance-spinner';
import { trackAmplitudeEvent } from 'utils/tracking';
import { AdvisorContext } from 'containers/advisor';
import withSubscription from 'hocs/subscription-validation';
import _ from 'lodash';
import { REVIEWED, isReportReadOnly } from 'pages/proposal-or-ips-reports/constants';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { generateSignedReport, getReportMetadata } from 'reports/utils';
import ReportViewer from 'reports/viewer';
import { allModelsWithPrismSelector } from 'selectors/models';
import { ipsTemplateListSelector } from 'selectors/templates';
import { getReportUrl } from 'utils/utils';
import IPSBody from './body';
import { IPSPropTypes } from './body/sections/types';
import IPSHeader from './header';
import IPSReportSignaturePage from './report/signature-page';
import './styles.scss';
import prepareMetadata from './utils';

const IPS = ({ accounts, currentReport, investor, ipsCharts, ips, models, params, templates }) => {
  const {
    accountProvider,
    clientProvider,
    ipsProvider,
    modelProvider,
    proposalProvider,
    templateProvider,
    user,
    userProvider
  } = useContext(AdvisorContext);

  const [signaturePage, setSignaturePage] = usePDF();

  const [actionsDisabled, setActionsDisabled] = useState(false);
  const [generatingReviewedReport, setGeneratingReviewedReport] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mainLoading, setMainLoading] = useState(false);

  const query = new URLSearchParams(window.location.search);
  const ready = !loading && !_.isEmpty(ips);
  const requiresReviewedReportGeneration =
    !_.isEmpty(ips) && currentReport?.status === REVIEWED && !currentReport?.signed_pdf_report;
  const showReadOnlyReport = currentReport?.status !== REVIEWED || currentReport?.signed_pdf_report;
  const withCustomBranding = userProvider.isBusinessOrAbove(user);

  const isProspect = window.location.pathname.includes('prospects');
  const isReadOnly =
    query.get(MODE_URL_PARAM) === READ_ONLY_MODE || isReportReadOnly(currentReport?.status);

  useEffect(() => {
    const initialLoad = [
      accountProvider.clearAccount(),
      clientProvider.get(params.id),
      clientProvider.getAccountsWithHousehold(params.id),
      templateProvider.list()
    ];

    setMainLoading(true);
    Promise.all(initialLoad).finally(() => {
      setMainLoading(false);
    });

    if (params.reportId) {
      setLoading(true);
      ipsProvider
        .getReport(params.reportId, params.id, isProspect)
        .then(async ({ data }) => {
          await ipsProvider.get(data.ips);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    modelProvider.listAll();

    return () => {
      ipsProvider.clear();
      ipsProvider.clearCurrentReport();
    };
  }, []);

  useEffect(() => {
    if (requiresReviewedReportGeneration) {
      setGeneratingReviewedReport(true);
      ipsProvider
        .getReportReviews(params.reportId, params.id, isProspect)
        .then(({ data: reviews }) => {
          setSignaturePage(
            <IPSReportSignaturePage
              initialPageNumber={currentReport.settings.signaturePage - 1}
              ips={ips}
              orientation={currentReport.settings.orientation}
              reviews={reviews}
              user={user}
              withCustomBranding={withCustomBranding}
            />
          );
        });
    }
  }, [JSON.stringify(ips), JSON.stringify(currentReport)]);

  useEffect(() => {
    const fetchSignedReport = async () => {
      await generateSignedReport(
        currentReport.pdf_report,
        signaturePage.url,
        currentReport.settings,
        getReportMetadata('Investment Policy Statement', investor.full_name)
      ).then(async signedReportBlobUrl => {
        if (signedReportBlobUrl)
          await ipsProvider
            .uploadPdfReport(signedReportBlobUrl, params.reportId, params.id, isProspect, true)
            .then(async () => {
              await ipsProvider.getReport(params.reportId, params.id, isProspect);
            });
      });
      setGeneratingReviewedReport(false);
    };
    if (signaturePage.url) fetchSignedReport();
  }, [signaturePage.url]);

  const generateIPS = values => {
    setLoading(true);
    const proposal = { ...values, start: values.period[0], end: values.period[1] };
    return ipsProvider
      .create({ proposal })
      .then(({ data }) => {
        trackAmplitudeEvent('ips.generated', {
          id: data.id,
          investor: investor.id,
          is_prospect: investor.is_prospect,
          with_recommended: !!proposal.recommended.length
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const saveChanges = (key, properties) => {
    const content = prepareMetadata(ips, user);
    const propertyContent = content[key];

    setActionsDisabled(true);
    return proposalProvider
      .update(ips.proposal.id, {
        template_content: { ...content, [key]: { ...propertyContent, ...properties } }
      })
      .then(async () => {
        await ipsProvider.get(ips.id);
        if (currentReport) ipsProvider.setCurrentReportUnsaved(true);
      })
      .finally(() => {
        setActionsDisabled(false);
      });
  };

  const toggleVisibility = key => () => {
    const content = prepareMetadata(ips, user);
    return saveChanges(key, { hidden: !content[key].hidden });
  };

  const toggleCustomAction = key => actionId => () => {
    const content = prepareMetadata(ips, user);
    const section = content[key];
    if (section.customActions && section.customActions[actionId]) {
      const newSectionContent = {
        ...section,
        customActions: {
          ...section.customActions,
          [actionId]: {
            ...section.customActions[actionId],
            value: !section.customActions[actionId].value
          }
        }
      };
      return saveChanges(key, newSectionContent);
    }
    return null;
  };

  if (_.isEmpty(accounts) || mainLoading) return <SpinnerLoader spinnerLoading />;

  return (
    <div id="IPS" className="ips">
      <div className="ips-container">
        <IPSHeader
          accountOptions={accounts.filter(a => !!a.prism_score_summary && !!a.target_score_summary)}
          entityId={ips?.id}
          generateIPS={generateIPS}
          loading={loading || generatingReviewedReport}
          modelOptions={models}
          proposalType={investor.is_prospect ? PROSPECT_PROPOSAL_TYPE : INVESTOR_PROPOSAL_TYPE}
          ready={ready}
          scope={investor}
          securityOptions={[]}
          templateOptions={templates}
        />

        {loading && !isReadOnly && (
          <LoadingPrismDataMessage
            message={params.reportId ? 'Getting IPS...' : 'Generating IPS...'}
            inset
          />
        )}

        {ready && !isReadOnly && (
          <IPSBody
            actionsDisabled={actionsDisabled}
            disclosure={null}
            ips={ips}
            ipsCharts={ipsCharts}
            isInvestorIPS
            isReadOnly={isReadOnly}
            saveChanges={saveChanges}
            toggleCustomAction={toggleCustomAction}
            toggleVisibility={toggleVisibility}
          />
        )}

        {isReadOnly && showReadOnlyReport && !generatingReviewedReport && (
          <ReportViewer url={getReportUrl(currentReport)} />
        )}

        {generatingReviewedReport && (
          <LoadingPrismDataMessage message="Generating Reviewed IPS Report..." inset />
        )}
      </div>
    </div>
  );
};

IPS.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.object),
  currentReport: PropTypes.object,
  investor: PropTypes.object,
  ips: PropTypes.shape(IPSPropTypes).isRequired,
  ipsCharts: PropTypes.object.isRequired,
  models: PropTypes.arrayOf(PropTypes.object),
  params: PropTypes.object.isRequired,
  templates: PropTypes.array.isRequired
};

IPS.defaultProps = {
  accounts: [],
  currentReport: null,
  investor: {},
  models: []
};

export default compose(
  withSubscription({
    plan: 'professional',
    id: 'ips',
    name: 'Investment Policy Statements',
    inline: true
  }),
  connect(state => ({
    accounts: investorAccountsWithHouseholdSelector(state),
    currentReport: state.ips.currentReport,
    investor: investorSelector(state),
    ips: state.ips.view,
    ipsCharts: state.ips.charts,
    models: allModelsWithPrismSelector(state),
    templates: ipsTemplateListSelector(state)
  }))
)(IPS);
