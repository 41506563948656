import cn from 'classnames';
import Disclosure from 'components/disclosure';
import Choice from 'components/form/choice';
import SignatureInput from 'components/form/signature-input';
import SpinnerLoader from 'components/performance-spinner';
import { AuthenticationContext } from 'containers/auth';
import { InvestorContext } from 'containers/investor';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ReportThumbnail from 'reports/thumbnail';
import ReportViewer from 'reports/viewer';
import { recordLogRocketEvent } from 'utils/tracking';
import { getReportUrl } from 'utils/utils';
import { IPS_REPORT_TYPE } from '../proposal-or-ips-reports/constants';
import SignatureRequsetMessage from './message';
import './styles.scss';

const PROSPECT_INVESTOR_TYPE = 'prospect';

const SIGNATURE_REQUEST_INITIAL_STEP = 0;
const SIGNATURE_REQUEST_REVIEW_STEP = 1;
const SIGNATURE_REQUEST_SIGN_STEP = 2;
const SIGNATURE_REQUEST_SUMMARY_STEP = 3;

const SignatureRequest = ({ params: { token } }) => {
  const { authProvider, user } = useContext(AuthenticationContext);
  const { ipsProvider, proposalProvider } = useContext(InvestorContext);

  const signatureRef = useRef(null);

  const [acknowledgeReview, setAcknowledgeReview] = useState(false);
  const [canSubmitSign, setCanSubmitSign] = useState(false);
  const [currentReport, setCurrentReport] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reviewedAt, setReviewedAt] = useState(null);
  const [step, setStep] = useState(SIGNATURE_REQUEST_INITIAL_STEP);
  const [submitting, setSubmitting] = useState(false);

  const params = new URLSearchParams(window.location.search);
  const reportId = Number(params.get('report'));
  const reportType = params.get('report_type');
  const reportInvestorId = Number(params.get('report_investor'));
  const reportInvestorType = params.get('report_investor_type');
  const requiresInvestorSignature = Boolean(
    user?.investor?.advisor?.company?.requires_investor_signature
  );
  const provider = reportType === IPS_REPORT_TYPE ? ipsProvider : proposalProvider;

  if (!token && !authProvider.isAuthenticated(user)) return null;

  const onChangeStep = step => () => {
    setStep(step);
  };

  const onChangeSubmitSignStatus = status => () => {
    setCanSubmitSign(status);
  };

  const onSignatureSubmit = async () => {
    const signature = signatureRef.current;
    if (signature) {
      setSubmitting(true);
      await provider
        .uploadSign(reportId, reportInvestorId, reportInvestorType === PROSPECT_INVESTOR_TYPE, {
          signature: signature.toDataURL(),
          investor: user.investor.id
        })
        .then(() => {
          setStep(SIGNATURE_REQUEST_SUMMARY_STEP);
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  const onAcknowledgeReview = async () => {
    setSubmitting(true);
    await provider
      .acknowledgeReview(
        reportId,
        reportInvestorId,
        reportInvestorType === PROSPECT_INVESTOR_TYPE,
        { investor: user.investor.id }
      )
      .then(() => {
        setStep(SIGNATURE_REQUEST_SUMMARY_STEP);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onSetAcknowledgeReview = () => {
    setAcknowledgeReview(prevAcknowledgeReview => !prevAcknowledgeReview);
  };

  useEffect(() => {
    const fetchReportReview = async () => {
      if (authProvider.isInvestor(user)) recordLogRocketEvent('Review Report');
      await provider
        .getReview(reportId, reportInvestorId, reportInvestorType === PROSPECT_INVESTOR_TYPE)
        .then(async ({ data, error }) => {
          if (data && !error) {
            setReviewedAt(data.reviewed_at);
            await provider
              .getReport(reportId, reportInvestorId, reportInvestorType === PROSPECT_INVESTOR_TYPE)
              .then(({ data: currentReport }) => {
                setCurrentReport(currentReport);
              });
          }
        });
      setLoading(false);
    };
    fetchReportReview();
  }, []);

  useEffect(() => {
    if (reviewedAt) setStep(SIGNATURE_REQUEST_REVIEW_STEP);
  }, [reviewedAt]);

  if (loading) return <SpinnerLoader spinnerLoading />;

  if (!currentReport)
    return <SignatureRequsetMessage messageId="signature-request.request-error" />;

  return (
    <>
      <div className="signature-request">
        <div className="signature-request__header">
          <h1>
            <FormattedMessage
              id="signature-request.title"
              values={{ type: reportType, investorName: user.investor.full_name }}
            />
          </h1>
        </div>

        <div
          className={cn('signature-request__content', {
            'content--summary': step === SIGNATURE_REQUEST_SUMMARY_STEP
          })}
        >
          {step === SIGNATURE_REQUEST_INITIAL_STEP && (
            <>
              <FormattedMessage id="signature-request.description" values={{ type: reportType }} />
              <button
                aria-label="Start Review"
                className="btn btn-primary btn-signature"
                onClick={onChangeStep(SIGNATURE_REQUEST_REVIEW_STEP)}
                type="button"
              >
                <FormattedMessage
                  id="signature-request.initial-button"
                  values={{ type: reportType }}
                />
              </button>
              <ReportThumbnail url={getReportUrl(currentReport)} />
            </>
          )}

          {step === SIGNATURE_REQUEST_REVIEW_STEP && reviewedAt && (
            <>
              <ReportViewer url={getReportUrl(currentReport)} />
              <div className="alert alert-success" role="alert">
                <i className="fs-icon-info-circled" />
                <FormattedMessage id="signature-request.request-signed" />
              </div>
            </>
          )}

          {step === SIGNATURE_REQUEST_REVIEW_STEP && !reviewedAt && (
            <>
              <ReportViewer url={getReportUrl(currentReport)} />
              {requiresInvestorSignature ? (
                <button
                  aria-label="Sign Report"
                  className="btn btn-success btn-signature"
                  onClick={onChangeStep(SIGNATURE_REQUEST_SIGN_STEP)}
                  type="button"
                >
                  <FormattedMessage id="signature-request.sign-button" values={{ submitting }} />
                </button>
              ) : (
                <>
                  <div className="acknowledge-report-confirmation">
                    <Choice
                      checked={acknowledgeReview}
                      id="acknowledge-report-confirmation-toggle"
                      toggle={onSetAcknowledgeReview}
                    />
                    <span className="provided-sync-data__label">
                      <FormattedMessage id="signature-request.acknowledge-confirmation" />
                    </span>
                  </div>
                  <button
                    aria-label="Acknowledge Report"
                    className="btn btn-success btn-signature"
                    disabled={submitting || !acknowledgeReview}
                    onClick={onAcknowledgeReview}
                    type="button"
                  >
                    <FormattedMessage
                      id="signature-request.submit-button"
                      values={{ submitting }}
                    />
                  </button>
                </>
              )}
            </>
          )}

          {step === SIGNATURE_REQUEST_SIGN_STEP && (
            <>
              <SignatureInput
                onBeginStroke={onChangeSubmitSignStatus(true)}
                onResetStroke={onChangeSubmitSignStatus(false)}
                signatureRef={signatureRef}
              />
              <div className="content__actions">
                <button
                  aria-label="Review Report"
                  className="btn btn-outline-secondary btn-signature"
                  onClick={onChangeStep(SIGNATURE_REQUEST_REVIEW_STEP)}
                  type="button"
                >
                  <FormattedMessage id="signature-request.back-button" />
                </button>
                <button
                  aria-label="Sign Report"
                  className="btn btn-primary btn-signature"
                  disabled={submitting || !canSubmitSign}
                  onClick={onSignatureSubmit}
                  type="button"
                >
                  <FormattedMessage id="signature-request.sign-button" values={{ submitting }} />
                </button>
              </div>
            </>
          )}

          {step === SIGNATURE_REQUEST_SUMMARY_STEP && (
            <>
              <FormattedMessage
                id="signature-request.summary-title"
                values={{
                  title: str => <p className="font-weight-bold">{str}</p>,
                  type: reportType
                }}
              />
              <FormattedMessage
                id="signature-request.summary-description"
                values={{ p: str => <p>{str}</p> }}
              />
            </>
          )}
        </div>
      </div>

      <Disclosure />
    </>
  );
};

SignatureRequest.propTypes = {
  params: PropTypes.object
};

SignatureRequest.defaultProps = {
  params: {}
};

export default SignatureRequest;
