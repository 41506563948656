import { IPSPropTypes } from 'components/advisor/ips/body/sections/types';
import Signature from 'components/advisor/templates/sections/signature/pdf';
import { AdvisorContext } from 'containers/advisor';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import BaseDocument from 'reports/base/document';
import BasePage from 'reports/base/page';
import CustomSection from 'reports/base/sections/custom';
import { PORTRAIT_ORIENTATION, SIZE_A4 } from 'reports/constants';
import { byTemplateSectionOrder } from 'utils/utils';
import prepareMetadata from '../../utils';
import SectionWrapper from '../section-wrapper';

const ACCOUNT_ONLY_SECTION = 'accountOnly';

const IPS_SIGNATURE_COMPONENTS = {
  _investmentPolicyReview: CustomSection,
  _signature: Signature
};

const IPSReportSignaturePage = ({
  initialPageNumber,
  ips,
  orientation,
  reviews,
  size,
  title,
  user,
  withCustomBranding
}) => {
  const getSectionsMetadata = () => {
    const bySectionHidden = ([_, metadata]) => !metadata.hidden;
    const bySectionType = ([_, metaSection]) => !metaSection[ACCOUNT_ONLY_SECTION];

    const sections = prepareMetadata(ips, user);

    return Object.entries(sections)
      .filter(bySectionHidden)
      .filter(bySectionType)
      .sort(byTemplateSectionOrder);
  };

  const sections = getSectionsMetadata();
  const signatureSections = sections.filter(([_, metadata]) => metadata.signature);
  const footerSection = sections.find(([_, metadata]) => metadata.footer);

  const context = useMemo(
    () => ({ orientation, user, withCustomBranding }),
    [JSON.stringify(user), orientation, withCustomBranding]
  );

  const advisor = ips?.proposal?.target?.investor?.advisor
    ? { ...ips.proposal.target.investor.advisor, company: user.advisor.company }
    : user.advisor;

  return (
    <BaseDocument title={title}>
      {/**
       * It's important to note that the context must be passed at this point to ensure that
       * the entire report has access to it. It's not possible to use react-redux connect on
       * child components that will be rendered by @react-pdf/renderer.
       */}
      <AdvisorContext.Provider value={context}>
        <BasePage
          advisor={advisor}
          footer={footerSection ? footerSection[1] : null}
          initialPageNumber={initialPageNumber}
          orientation={orientation}
          size={size}
          withCustomBranding={withCustomBranding}
        >
          {signatureSections.map(([key, metadata]) => (
            <SectionWrapper
              body={metadata.body}
              component={IPS_SIGNATURE_COMPONENTS[key]}
              ips={ips}
              key={key}
              metadata={metadata}
              orientation={orientation}
              propertyKey={key}
              reviews={reviews}
              title={metadata.title}
            />
          ))}
        </BasePage>
      </AdvisorContext.Provider>
    </BaseDocument>
  );
};

IPSReportSignaturePage.propTypes = {
  initialPageNumber: PropTypes.number,
  ips: PropTypes.shape(IPSPropTypes).isRequired,
  orientation: PropTypes.string,
  reviews: PropTypes.array.isRequired,
  size: PropTypes.string,
  title: PropTypes.string,
  user: PropTypes.object.isRequired,
  withCustomBranding: PropTypes.bool
};

IPSReportSignaturePage.defaultProps = {
  initialPageNumber: 0,
  orientation: PORTRAIT_ORIENTATION,
  size: SIZE_A4,
  title: 'Investment Policy Statement Signatures',
  withCustomBranding: false
};

export default IPSReportSignaturePage;
