import { SEPARATOR } from 'components/advisor/utils/edit-box';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { DISABLED_COMPONENT_PROPS } from 'components/advisor/utils/edit-box-text/utils';
import { MetadataPropTypes } from './types';

const useProposalMetadata = ({
  actionsDisabled,
  metadata,
  propertyKey,
  setMetadata,
  isDisabled
}) => {
  const [editing, setEditing] = useState(false);

  const {
    allowChangeText,
    allowEdit,
    allowHide,
    body,
    custom,
    customActions,
    hidden,
    preparedBy,
    preparedFor,
    title
  } = metadata;
  const prepend = body ? body.split(SEPARATOR).splice(1).join('') : '';

  const toggleVisibility = () => {
    const newMetadata = { ...metadata, hidden: !hidden };
    return setMetadata(propertyKey, newMetadata);
  };

  const toggleCustomAction = actionId => () => {
    if (metadata.customActions && metadata.customActions[actionId]) {
      const newMetadata = {
        ...metadata,
        customActions: {
          ...metadata.customActions,
          [actionId]: {
            ...metadata.customActions[actionId],
            value: !metadata.customActions[actionId].value
          }
        }
      };
      return setMetadata(propertyKey, newMetadata);
    }
    return null;
  };

  const toggleEditing = () => {
    setEditing(!editing);
  };

  const setBody = (_, { body, allowEdit, allowHide, hidden, preparedBy, preparedFor, title }) => {
    const newMetadata = {
      ...metadata,
      body,
      allowEdit,
      allowHide,
      hidden,
      preparedBy,
      preparedFor,
      title
    };
    return setMetadata(propertyKey, newMetadata);
  };

  return {
    actionsDisabled,
    allowChangeText,
    allowEdit,
    allowHide,
    body,
    custom,
    customActions,
    editing,
    hidden,
    initialValues: { body, prepend, title },
    preparedBy,
    preparedFor,
    propertyKey,
    saveChanges: setBody,
    title,
    toggleCustomAction,
    toggleEditing,
    toggleVisibility,
    ...(isDisabled ? DISABLED_COMPONENT_PROPS : {})
  };
};

useProposalMetadata.defaultProps = {
  actionsDisabled: false,
  isDisabled: false
};

useProposalMetadata.propTypes = {
  actionsDisabled: PropTypes.bool,
  metadata: PropTypes.shape(MetadataPropTypes).isRequired,
  propertyKey: PropTypes.string.isRequired,
  setMetadata: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool
};

export default useProposalMetadata;
