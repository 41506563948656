import DataProvider from 'providers/data';
import config from '../config';

class UserProvider extends DataProvider {
  getAdvisor(user) {
    if (!user) return null;

    let advisor;
    if (user.advisor) ({ advisor } = user);
    else if (user.investor) ({ advisor } = user.investor);

    return advisor;
  }

  // ============================  Product Access  ============================= //
  getProduct(user) {
    // users without automated billing should have company.product
    const advisor = this.getAdvisor(user);
    const product = advisor?.company?.product;
    return product ? product.split('-')[0].toLowerCase() : '';
  }

  isEntry(user) {
    const price = this.getProduct(user);
    return price === 'freemium' || price === 'basic' || price === 'starter';
  }

  isProfessional(user) {
    const price = this.getProduct(user);
    return price === 'professional';
  }

  isBusiness(user) {
    const price = this.getProduct(user);
    return price === 'business';
  }

  isEnterprise(user) {
    const price = this.getProduct(user);
    return price === 'enterprise';
  }

  isEntryOrAbove(user) {
    return (
      this.isEntry(user) ||
      this.isProfessional(user) ||
      this.isBusiness(user) ||
      this.isEnterprise(user)
    );
  }

  isProfessionalOrAbove(user) {
    return this.isProfessional(user) || this.isBusiness(user) || this.isEnterprise(user);
  }

  isBusinessOrAbove(user) {
    return this.isBusiness(user) || this.isEnterprise(user);
  }

  isEnterpriseOrAbove(user) {
    return this.isEnterprise(user);
  }

  hasAccess(user, plan) {
    if (plan === 'freemium' || plan === 'basic' || plan === 'starter')
      return this.isEntryOrAbove(user);
    if (plan === 'professional') return this.isProfessionalOrAbove(user);
    if (plan === 'business') return this.isBusinessOrAbove(user);
    if (plan === 'enterprise') return this.isEnterpriseOrAbove(user);
    return false;
  }

  isPending = user => {
    const advisor = this.getAdvisor(user);
    return advisor?.company?.status === 'pending';
  };

  isTrialing = user => {
    const advisor = this.getAdvisor(user);
    return advisor?.company?.status === 'trial';
  };

  // ============================  Me  ============================= //
  update(values) {
    return this.provider.patch(`${config.apiBase}me/advisor/`, values);
  }

  updateImage(file, field = 'image') {
    const formData = this.formData({ file });
    return this.provider.postFormData(
      `${config.apiBase}me/advisor/${field}/`,
      formData,
      null,
      'PATCH'
    );
  }
}

export default UserProvider;
