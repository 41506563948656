import { View } from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import React from 'react';
import CustomSection from 'reports/base/sections/custom';

const SECTIONS_FOR_CUSTOM_STYLES = [
  'purpose',
  'background',
  'executiveSummary',
  'statementOfObjectives',
  'governance'
];

const SectionWrapper = ({
  component,
  propertyKey,
  setAttachments,
  setSignaturePageNumber,
  ...rest
}) => {
  const { metadata } = rest;

  // returns a dummy element in charge of simulating in which page the attachment type section
  // should be positioned. In this way, it's possible to determine on which page number it would be
  // and then merge it with the main document on the required page.
  if (metadata.attachment)
    return (
      <View
        render={({ pageNumber, totalPages }) => {
          if (Number.isFinite(totalPages) && setAttachments)
            setAttachments(prevAttachments => ({
              ...prevAttachments,
              [metadata.attachmentId]: { pageNumber, url: metadata.url }
            }));
        }}
      />
    );

  const Component = component ?? CustomSection;
  const sectionStyles =
    !component || SECTIONS_FOR_CUSTOM_STYLES.includes(propertyKey) ? { marginBottom: 20 } : {};

  // returns both the component needed and a dummy element in charge of getting the page in which
  // the signature type section should be positioned (If applicable). In this way, it's possible to
  // determine on which page number it would be and then replace it when all signatures are collected.
  return (
    <>
      {metadata.signature && (
        <View
          render={({ pageNumber, totalPages }) => {
            if (Number.isFinite(totalPages) && setSignaturePageNumber)
              setSignaturePageNumber(pageNumber);
          }}
        />
      )}
      <Component
        breakSection={metadata.customActions?.breakSection?.value ?? false}
        sectionStyles={sectionStyles}
        {...rest}
      />
    </>
  );
};

SectionWrapper.propTypes = {
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  metadata: PropTypes.object.isRequired,
  propertyKey: PropTypes.string.isRequired,
  setAttachments: PropTypes.func,
  setSignaturePageNumber: PropTypes.func
};

SectionWrapper.defaultProps = {
  component: null,
  setAttachments: null,
  setSignaturePageNumber: null
};

export default SectionWrapper;
