const PROVIDED_DATA_PREFIX = 'sync_';

const PROVIDED_DATA_MAPPING = [
  { id: 'sync_accounts', name: 'Accounts', description: '' },
  { id: 'sync_advisors', name: 'Advisors', description: '' },
  { id: 'sync_clients', name: 'Clients', description: '' },
  { id: 'sync_households', name: 'Households', description: '' },
  { id: 'sync_model_portfolios', name: 'Model Portfolios', description: '' },
  { id: 'sync_prospects', name: 'Prospects', description: '' }
];

const getSyncDataOptions = element => {
  const providedData = Object.keys(element).filter(
    key => element[key] && key.startsWith(PROVIDED_DATA_PREFIX)
  );

  return PROVIDED_DATA_MAPPING.filter(data => providedData.includes(data.id));
};

const getIntegrationsSyncData = integrations =>
  integrations
    .map(integration =>
      Object.keys(integration).filter(
        key => integration[key] && key.startsWith(PROVIDED_DATA_PREFIX)
      )
    )
    .flat();

const getIntegrationsSyncDataByProviders = integrations =>
  integrations.reduce((acc, i) => {
    // Integration synced data
    const data = Object.keys(i).filter(key => i[key] && key.startsWith(PROVIDED_DATA_PREFIX), {});
    // Reduced data as key-values object, e.g. { syncedData: providerName, ... }
    const result = data.reduce((acc, d) => ({ ...acc, [d]: i.provider_details.name }), {});
    return { ...acc, ...result };
  }, {});

const hasCustomIntegrationHandler = ({ provider, name }) =>
  name === 'Raymond James' ||
  provider === 'Addepar' ||
  provider === 'Altruist' ||
  provider === 'BlackDiamond' ||
  provider === 'ByAllAccounts' ||
  provider === 'Charles Schwab' ||
  provider === 'Charles Schwab OAuth' ||
  provider === 'Orion' ||
  provider === 'Redtail' ||
  provider === 'Salesforce' ||
  provider === 'Wealthbox';

const sortProviders = providers => {
  providers.sort((a, b) => {
    if (hasCustomIntegrationHandler(a) && !hasCustomIntegrationHandler(b)) return -1;

    if (!hasCustomIntegrationHandler(a) && hasCustomIntegrationHandler(b)) return 1;

    if (a.external_url && !b.external_url) return -1;

    if (!a.external_url && b.external_url) return 1;

    if (a.is_enabled && !b.is_enabled) return -1;

    if (!a.is_enabled && b.is_enabled) return 1;

    return 0;
  });
  return providers;
};
export {
  getIntegrationsSyncData,
  getIntegrationsSyncDataByProviders,
  getSyncDataOptions,
  hasCustomIntegrationHandler,
  sortProviders
};
