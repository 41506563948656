import cn from 'classnames';
import { Modal, ModalBody } from 'components/modal';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import CreateAccountsAutomatically from '../create-accounts-automatically';
import MergeAccounts from '../merge-accounts';
import PushScores from '../push-scores';
import SyncDataOptions from '../sync-data-options';
import './styles.scss';

const SELECT_SYNC_DATA_OPTIONS_STEP = 1;
const UPDATE_INTEGRATION_SYNC_DATA_OPTIONS_STEP = 2;

const CREATE_ACCOUNTS_AUTOMATICALLY_STEP = 3;
const MERGE_ACCOUNTS_STEP = 4;
const PUSH_SCORES_STEP = 6;

const UpdateIntegrationModal = ({ onCancel, onUpdate, prevSelectedSyncData, provider, show }) => {
  const { provider: providerId } = provider;
  const [step, setStep] = useState(SELECT_SYNC_DATA_OPTIONS_STEP);
  const [selectedSyncData, setSelectedSyncData] = useState({});

  useEffect(() => {
    if (step === UPDATE_INTEGRATION_SYNC_DATA_OPTIONS_STEP)
      if (onUpdate) onUpdate(selectedSyncData);
  }, [step]);

  const getSelectedSyncData = data => {
    const { sync_accounts: syncAccounts, sync_clients: syncClients } = data;

    setSelectedSyncData(data);

    if (providerId === 'ByAllAccounts' && syncAccounts && syncClients)
      setStep(CREATE_ACCOUNTS_AUTOMATICALLY_STEP);
    else if (providerId === 'Orion' && syncAccounts) setStep(MERGE_ACCOUNTS_STEP);
    else if (providerId === 'Addepar') setStep(PUSH_SCORES_STEP);
    else setStep(UPDATE_INTEGRATION_SYNC_DATA_OPTIONS_STEP);
  };

  const getAdditionalConfigResponse = data => {
    setSelectedSyncData({ ...selectedSyncData, ...data });
    setStep(UPDATE_INTEGRATION_SYNC_DATA_OPTIONS_STEP);
  };

  return (
    <Modal
      id="update-integration-modal"
      className={cn('modal-lg', {
        'extra-step-bigger-modal':
          step === CREATE_ACCOUNTS_AUTOMATICALLY_STEP || step === PUSH_SCORES_STEP
      })}
      show={show}
      onHidden={onCancel}
    >
      <ModalBody>
        {step === SELECT_SYNC_DATA_OPTIONS_STEP && (
          <SyncDataOptions
            onConnectOrUpdate={getSelectedSyncData}
            prevSelectedSyncData={prevSelectedSyncData}
            provider={provider}
            updating
          />
        )}
        {step === UPDATE_INTEGRATION_SYNC_DATA_OPTIONS_STEP && (
          <>
            <p className="mt-1">
              Your update for {provider.name} integration will take up to 24 hours
            </p>
            <button type="button" onClick={onCancel} className="btn btn-primary mt-2">
              Close
            </button>
          </>
        )}
        {step === CREATE_ACCOUNTS_AUTOMATICALLY_STEP && (
          <CreateAccountsAutomatically onComplete={getAdditionalConfigResponse} />
        )}
        {step === MERGE_ACCOUNTS_STEP && <MergeAccounts onComplete={getAdditionalConfigResponse} />}
        {step === PUSH_SCORES_STEP && <PushScores onComplete={getAdditionalConfigResponse} />}
      </ModalBody>
    </Modal>
  );
};

UpdateIntegrationModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func,
  prevSelectedSyncData: PropTypes.array,
  provider: PropTypes.object.isRequired,
  show: PropTypes.bool.isRequired
};

UpdateIntegrationModal.defaultProps = {
  onUpdate: () => {},
  prevSelectedSyncData: []
};

export default UpdateIntegrationModal;
